<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span mat-dialog-title>
    {{ 'modal_dialog_please_select' | translate }}
    {{ 'entity_fo_propertycolumn' | translate }}
  </span>
</div>
<div mat-dialog-content class="dialog-with-bottom-buttons">
  <!-- DIALOG CONTENT -->
  <div id="entity-table" class="entity-table">
    <div class="table-wrapper">
      <div class="table-search-paginate hidden-filters">
        <div class="table-search-paginate-left-wrapper">
          <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
            <input
              #searchInputField
              matInput
              [(ngModel)]="filterValue"
              (debounceFilterInput)="applyFilterTable($event)"
              [debounceTime]="600"
              maxlength="1500"
              placeholder="{{ 'table_filter_placeholder' | translate }}"
            />
          </mat-form-field>
          <button mat-button color="primary" (click)="resetFilter()" *ngIf="isFiltersSetted()">
            {{ 'label_reset_filters' | translate }}
          </button>
        </div>
        <div class="table-search-paginate-right"></div>
      </div>
      <div class="table-filters visible">
        <generic-grid-filter-panel
          [clearFilter]="clearFilterSubject.asObservable()"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="PropertyColumnParams.FILTER_PROPERTY_IDS"
          [filterApiPath]="ApiPath.Properties.PROPERTY_COLUMNS_PROPERTIES"
          filterTitle="{{ 'filter_property' | translate }}"
          (filterIdsEvEm)="setFiltersIds($event, PropertyColumnParams.FILTER_PROPERTY_IDS)"
        ></generic-grid-filter-panel>
        <generic-grid-filter-panel
          [clearFilter]="clearFilterSubject.asObservable()"
          [filtersMapSubj]="filtersMapSubject.asObservable()"
          [filterName]="PropertyColumnParams.FILTER_TYPE_IDS"
          [filterApiPath]="ApiPath.Properties.PROPERTY_COLUMNS_TYPES"
          filterTitle="{{ 'filter_type' | translate }}"
          (filterIdsEvEm)="setFiltersIds($event, PropertyColumnParams.FILTER_TYPE_IDS)"
        ></generic-grid-filter-panel>
      </div>
      <div class="table-container">
        <table
          class="table table-stripes table-truncate-text"
          mat-table
          [dataSource]="dataSourceTable"
          matSort
          #sortTable="matSort"
          [matSortActive]="gbGetInitialOrderBy()"
          [matSortDirection]="gbGetInitialSort()"
          matSortDisableClear
        >
          <!-- radio button column -->
          <ng-container matColumnDef="radio">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
              <mat-radio-button
                class="table-radio-button"
                (change)="onEntitySelected(row)"
                disableRipple="true"
                [checked]="selectedEntity && selectedEntity.entityId === row.entityId"
              ></mat-radio-button>
            </td>
          </ng-container>
          <ng-container matColumnDef="propertycolumnProperty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'propertycolumn_property' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.propertycolumnProperty" showIfTruncated>
              {{ row.propertycolumnProperty }}
            </td>
          </ng-container>
          <ng-container matColumnDef="propertycolumnName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'propertycolumn_name' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.propertycolumnName" showIfTruncated>
              {{ row.propertycolumnName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="propertycolumnType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'propertycolumn_type' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.propertycolumnType" showIfTruncated>
              {{ row.propertycolumnType }}
            </td>
          </ng-container>
          <ng-container matColumnDef="propertycolumnForeignProperty">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'propertycolumn_foreign_property' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.propertycolumnForeignProperty" showIfTruncated>
              {{ row.propertycolumnForeignProperty }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr
            mat-row
            (click)="onEntitySelected(row)"
            *matRowDef="let row; columns: displayedColumnsTable"
            [ngClass]="selectedEntity && selectedEntity.entityId === row.entityId ? 'selected-row' : ''"
          ></tr>
        </table>
        <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
          <mat-toolbar-row *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-toolbar-row>
          <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
            <span>{{ 'generic_table_error_nodata' | translate }}</span>
          </mat-toolbar-row>
        </mat-toolbar>
        <mat-toolbar class="table-footer-toolbar">
          <mat-toolbar-row>
            <mat-paginator
              [disabled]="isLoading"
              #paginatorTable
              [pageSizeOptions]="pageSizeList"
              [length]="pageTotalElements"
              class="reset-paginator"
            ></mat-paginator>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </div>
  </div>
  <div class="particulars-form no-background dialog-bottom-buttons">
    <div class="particulars-row no-label">
      <div class="particulars-col"></div>
      <div class="particulars-col"></div>
      <div class="particulars-col float-right">
        <span class="control-value button float-right">
          <button mat-stroked-button color="primary" [mat-dialog-close]="null" style="margin-right: 0.5rem">
            <span>{{ 'label_cancel' | translate }}</span>
          </button>
          <button mat-flat-button color="primary" [mat-dialog-close]="selectedEntity" [disabled]="!isEntitySelected()">
            <span>{{ 'label_select' | translate }}</span>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>

import { ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';
import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import insertTextAtCursor from 'insert-text-at-cursor';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import Base64UploaderPlugin from 'src/@ckeditor/Base64Upload';
import { Entities } from 'src/app/configs/entities';
import {
  MailPreviewModalDialogComponent,
  MailPreviewModalDialogData,
} from 'src/app/configurations-pages/mail-template/mail-preview-modal-dialog/mail-preview-modal-dialog.component';
import { Entity } from 'src/app/models/entity';
import { ForeignEntity } from 'src/app/models/foreign-entity';
import {
  FormFieldInputfieldBooleanDisplayOption,
  FormFieldInputfieldOptionDisplayOption,
} from 'src/app/models/forms/form-configuration-option';
import { GenericFormFieldItem, GenericFormFieldRequiredType } from 'src/app/models/forms/form-field';
import { MailOutgoingAccount } from 'src/app/models/mail-server';
import {
  MailTemplateFrame,
  MailTemplateFrameBody,
  MailTemplateStage,
  MailTemplateStaticsOptions,
} from 'src/app/models/mailtemplate/mail-template-particular';
import { LogService } from 'src/app/services/log-service';
import { MailTemplatesDataService } from 'src/app/services/mail-template-data.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import {
  GenericEntityWizardModalDialogComponent,
  GenericEntityWizardModalDialogData,
} from '../../../generic-entity-wizard-modal-dialog/generic-entity-wizard-modal-dialog.component';
import { BaseFormFieldComponent } from '../base-form-field/base-form-field-component';

@Component({
  selector: 'app-form-field-inputfield',
  templateUrl: './form-field-inputfield.component.html',
  styleUrls: ['./form-field-inputfield.component.scss'],
})
export class FormFieldInputfieldComponent extends BaseFormFieldComponent implements OnInit {
  @ViewChild('autoStringOption') autocomplete: MatAutocomplete;
  @ViewChild('autoForeignOption') autocompleteForeing: MatAutocomplete;
  @ViewChild('ckeditor') ckeditor: CKEditorComponent;
  @Input() rowHeight: number;
  @Input() showForeignDetailsButton: boolean;
  @Input() foreignFilterEntityKind: string;
  @Input() foreignFilterEntityId: number;

  public Editor = ClassicEditor;
  public config;
  isStringInputMenuOpen = false;
  overlayPositions: ConnectedPosition[] = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
  ];

  closeOverlay() {}

  filteredEntityOptions: KeyValue<string, string>[] = [];
  // foreignEntityOptions: ForeignEntity[];
  // bstTheme: NgxMaterialTimepickerTheme = {
  //   dial: {
  //     dialBackgroundColor: this.$mainThemeColor,
  //   },
  //   clockFace: {
  //     clockHandColor: this.$mainThemeColor,
  //   },
  // };
  datepickerDirty = false;
  originalFormFieldData: GenericFormFieldItem;

  bitwiseSelection: KeyValue<string, string>[];

  // filterStringOption: string;

  //Mail template
  staticOptions: MailTemplateStaticsOptions;
  selectedStage: MailTemplateStage;
  selectedLanguage: Entity;
  selectedFontfamily: Entity;
  selectedTemplate: Entity;
  selectedOutgoingAccount: MailOutgoingAccount;
  mailTemplateFrame: MailTemplateFrame;
  originalMailTemplateFrame: MailTemplateFrame;
  selectedMailTemplateFrameBody: MailTemplateFrameBody;
  originalSelectedMailTemplateFrameBody: MailTemplateFrameBody;

  constructor(
    private cdRef: ChangeDetectorRef,
    private mailTemplateService: MailTemplatesDataService,
    private sanitizer: DomSanitizer,
    protected dialog: MatDialog,
    private messageNotifierService: MessageNotifierService
  ) {
    super();

    this.config = {
      heading: {
        options: [
          { model: 'paragraph', title: this.translate.instant(_('ckeditor_paragraph')), class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: this.translate.instant(_('ckeditor_heading1')), class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: this.translate.instant(_('ckeditor_heading2')), class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: this.translate.instant(_('ckeditor_heading3')), class: 'ck-heading_heading3' },
        ],
      },
      language: this.translate.currentLang,
      extraPlugins: [Base64UploaderPlugin],
      link: { addTargetToExternalLinks: true },
    };
  }

  async ngOnInit() {
    // super.ngOnInit();
    this.filteredEntityOptions = this.formFieldData.formfieldEntityOptions;
    if (this.saveSubj) {
      this.subscribe(this.saveSubj, () => (this.isSavePressed = true));
    }
    if (this.validationSubj) {
      this.subscribe(this.validationSubj, (failed) => (this.isValidationFailed = failed));
    }
    if (this.formFieldData.formfieldEntityType === 'mailtemplate') {
      await this.loadMailTemplateOptions();
      this.mailTemplateFrame = JSON.parse(this.formFieldData.formfieldEntityText);
      if (this.mailTemplateFrame.templateId != null) {
        this.selectedTemplate =
          this.staticOptions.templates && this.staticOptions.templates.length > 0
            ? this.staticOptions.templates.find((t) => t.entityId === this.mailTemplateFrame.templateId)
            : null;
        this.selectedFontfamily =
          this.staticOptions.fontfamilies && this.staticOptions.fontfamilies.length > 0
            ? this.staticOptions.fontfamilies.find((t) => t.entityId === this.mailTemplateFrame.fontfamilyId)
            : null;
        this.selectedOutgoingAccount =
          this.staticOptions.mailoutgoingaccounts && this.staticOptions.mailoutgoingaccounts.length > 0
            ? this.staticOptions.mailoutgoingaccounts.find((t) => t.entityId === this.mailTemplateFrame.mailoutgoingaccountId)
            : null;
      } else {
        this.selectedTemplate =
          this.staticOptions.templates && this.staticOptions.templates.length > 0 ? this.staticOptions.templates[0] : null;
        this.selectedFontfamily =
          this.staticOptions.fontfamilies && this.staticOptions.fontfamilies.length > 0 ? this.staticOptions.fontfamilies[0] : null;
        this.selectedOutgoingAccount =
          this.staticOptions.mailoutgoingaccounts && this.staticOptions.mailoutgoingaccounts.length > 0
            ? this.staticOptions.mailoutgoingaccounts[0]
            : null;
        this.mailTemplateFrame.templateId = this.selectedTemplate ? this.selectedTemplate.entityId : null;
        this.mailTemplateFrame.fontfamilyId = this.selectedFontfamily ? this.selectedFontfamily.entityId : null;
        this.mailTemplateFrame.mailoutgoingaccountId = this.selectedOutgoingAccount ? this.selectedOutgoingAccount.entityId : null;
        this.formFieldData.formfieldEntityText = JSON.stringify(this.mailTemplateFrame);
        this.changeValueEvEm.emit();
      }
      this.selectedMailTemplateFrameBody = this.mailTemplateFrame.bodies.find(
        (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === b.stageId
      );
      if (this.isDirtyEnabled) {
        this.originalMailTemplateFrame = JSON.parse(JSON.stringify(this.mailTemplateFrame));
        this.originalSelectedMailTemplateFrameBody = this.originalMailTemplateFrame.bodies.find(
          (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === b.stageId
        );
      }
      if (this.mailpreviewSubj) {
        this.subscribe(this.mailpreviewSubj, () => this.showPreview());
      }
    }
    if (this.isDirtyEnabled) {
      this.originalFormFieldData = JSON.parse(JSON.stringify(this.formFieldData));
    }
    if (
      this.formFieldData.formfieldEntityType === 'bitset' &&
      this.formFieldData.formfieldDisplayType === FormFieldInputfieldOptionDisplayOption.COMBOBOX
    ) {
      this.bitwiseSelection = this.formFieldData.formfieldEntityOptions.filter((opt) =>
        this.bitwiseAnd(this.castToInt(opt.key), this.formFieldData.formfieldEntityValue)
      );
    }
    if (this.formFieldData.formfieldEntityType === 'option' && this.formFieldData.formfieldEntityKind !== Entities.TYPES) {
      const optionIndex = this.formFieldData.formfieldEntityOptions.findIndex(
        (o) =>
          this.castToInt(o.key) === this.formFieldData.formfieldEntityValue ||
          o.key === this.formFieldData.formfieldEntityText ||
          o.value === this.formFieldData.formfieldEntityText
      );
      if (optionIndex !== -1) {
        this.formFieldData.filterStringOption = this.formFieldData.formfieldEntityOptions[optionIndex].value;
        // WORKAROUND - Mat Autocomplete didn't select at its initialization the default option.
        setTimeout(() => {
          const opt = this.autocomplete.options.get(optionIndex);
          opt.select();
        }, 0);
      }
    }
    // if (this.formFieldData.formfieldEntityType === 'foreign') {
    //   this.foreignEntityOptions =
    //     this.formFieldData.formfieldEntityText != null && this.formFieldData.formfieldEntityValue != null
    //       ? [
    //           {
    //             ...new ForeignEntity(),
    //             entityId: this.formFieldData.formfieldEntityValue,
    //             entityName: this.formFieldData.formfieldEntityText,
    //           },
    //         ]
    //       : [];
    // }
  }

  filterOptions(filter: string) {
    if (filter) {
      this.filteredEntityOptions = this.formFieldData.formfieldEntityOptions
        ? this.formFieldData.formfieldEntityOptions.filter((kv) => kv.value.toLowerCase().includes(filter.toLowerCase()))
        : [];
      this.formFieldData.formfieldEntityText = null;
      this.formFieldData.formfieldEntityValue = null;
      this.changeValueEvEm.emit();
    } else {
      this.filteredEntityOptions = this.formFieldData.formfieldEntityOptions;
      this.formFieldData.formfieldEntityText = null;
      this.formFieldData.formfieldEntityValue = null;
      this.changeValueEvEm.emit();
    }
  }

  checkSelectedOption() {
    if (this.formFieldData.formfieldEntityType === 'option' && this.formFieldData.formfieldEntityKind !== Entities.TYPES) {
      if (
        !this.formFieldData.formfieldEntityOptions.find(
          (kv) => kv.key === this.formFieldData.filterStringOption || kv.value === this.formFieldData.filterStringOption
        )
      ) {
        this.formFieldData.formfieldEntityText = null;
        this.formFieldData.formfieldEntityValue = null;
        this.formFieldData.filterStringOption = null;
        this.filterOptions(null);
        this.changeValueEvEm.emit();
      }
    }
    // else if (this.formFieldData.formfieldEntityType === 'foreign') {
    //   // if (!this.foreignEntityOptions || !this.foreignEntityOptions.find((fe) => fe.entityName === this.formFieldData.formfieldEntityText)) {
    //   //   this.formFieldData.formfieldEntityText = null;
    //   //   this.formFieldData.formfieldEntityValue = null;
    //   //   this.changeValueEvEm.emit();
    //   // }
    // }
  }

  createEntityTypeOption() {
    const openedDialogs = this.dialog.openDialogs.length;
    const dialogRef = this.dialog.open(GenericEntityWizardModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-entity-wizard-modal-dialog',
      disableClose: true,

      data: {
        wizardName: this.formFieldData.formfieldEntityKind,
        relatedEntityKinds: ['entity_types_kind'],
        relatedEntityIds: [this.castToInt(this.formFieldData.formfieldEntitySpecification)],
        isDocumentsTabEnabled: this.isDocumentEnabled,
      } as GenericEntityWizardModalDialogData,
    });
    if (openedDialogs > 0) {
      const elemRect = this.dialog.openDialogs[openedDialogs - 1]._containerInstance['_elementRef'].nativeElement.getBoundingClientRect();
      const top = elemRect.top + 10;
      const left = elemRect.left + 10;
      dialogRef.updatePosition({ top: `${top}px`, left: `${left}px` });
    }
    this.subscribe(dialogRef.afterClosed(), (entity) => {
      if (entity) {
        this.formFieldData.filterStringOption = (entity as ForeignEntity).entityName;
        this.formFieldData.formfieldEntityText = (entity as ForeignEntity).entityName;
        this.formFieldData.formfieldEntityValue = (entity as ForeignEntity).entityId;
        this.formFieldData.formfieldEntityOptions.push({
          key: (entity as ForeignEntity).entityId.toString(),
          value: (entity as ForeignEntity).entityName,
          addon: null,
        });
        this.formFieldData.formfieldEntityOptions.sort((a: KeyValue<string, string>, b: KeyValue<string, string>) => {
          if (a.value < b.value) {
            return -1;
          } else if (a.value > b.value) {
            return 1;
          } else {
            return 0;
          }
        });
        this.autoUpdateEvEm.emit();
        this.changeValueEvEm.emit();
      }
    });
  }

  onOptionSelectionChanged($event) {
    const selectedKeyvalue = this.formFieldData.formfieldEntityOptions.find((kv) => kv.value === $event.option.value);
    this.formFieldData.filterStringOption = selectedKeyvalue.value;
    this.formFieldData.formfieldEntityText = selectedKeyvalue.key;
    this.formFieldData.formfieldEntityValue = this.castToInt(selectedKeyvalue.key);
    this.filteredEntityOptions = this.formFieldData.formfieldEntityOptions;
    this.autoUpdateEvEm.emit();
    this.changeValueEvEm.emit();
  }

  onRadioSelectionChanged($event: MatRadioChange) {
    const selectedKeyvalue = this.formFieldData.formfieldEntityOptions.find((kv) => this.castToInt(kv.key) === $event.value);
    this.formFieldData.formfieldEntityValue = this.castToInt(selectedKeyvalue.key);
    this.formFieldData.formfieldEntityText = selectedKeyvalue.value;
    this.autoUpdateEvEm.emit();
    this.changeValueEvEm.emit();
  }

  onChangeBoolean(event: MatCheckboxChange | MatSlideToggleChange) {
    this.formFieldData.formfieldEntityValue = event.checked ? 1 : 0;
    this.formFieldData.formfieldEntityText = event.checked ? this.translate.instant('label_true') : this.translate.instant('label_false');
    this.changeValueEvEm.emit();
  }

  onColorChange($event: string) {
    this.formFieldData.formfieldEntityText = $event;
    this.formFieldData.formfieldEntityValue = parseInt($event.split('#')[1], 16);
    this.changeValueEvEm.emit();
  }

  onTranslationsChange($event: string) {
    // this.formFieldData.formfieldEntityText = $event;
    this.changeValueEvEm.emit();
  }

  convertFile(fileList: FileList) {
    if (fileList && fileList.length > 0) {
      const file = fileList.item(0);
      LogService.debug(this, this.convertFile.name, 'Loaded File', file);
      if (file.type.match(this.formFieldData.formfieldEntitySpecification) && file.size < this.formFieldData.formfieldEntityScale) {
        const reader = new FileReader();
        reader.readAsDataURL(fileList.item(0));
        reader.onload = () => {
          this.formFieldData.formfieldEntityText = reader.result;
          this.changeValueEvEm.emit();
        };
      } else if (file.size >= this.formFieldData.formfieldEntityScale) {
        this.messageNotifierService.showWarningMessage(_('label_invalid_file_size'));
      } else {
        this.messageNotifierService.showWarningMessage(_('label_invalid_file_type'));
      }
    }
  }

  updateFloatText(event) {
    const { value } = event.target;
    if (value !== '') {
      // this.formFieldData.formfieldEntityFloatText = value;
    } else {
      this.formFieldData.formfieldEntityFloatText = null;
    }
  }

  updateMoney(event) {
    const { value } = event.target;
    if (value !== '') {
      if (!this.checkDecimalFormat(value)) {
        return;
      }
      // this.formFieldData.formfieldEntityFloatText = value;
      this.formFieldData.formfieldEntityText = this.castToFloat2Decimal(value).toFixed(2);
      this.formFieldData.formfieldEntityValue = this.castToFloat2Decimal(value); // is really needed?
      this.changeValueEvEm.emit();
    } else {
      this.formFieldData.formfieldEntityFloatText = null;
      this.formFieldData.formfieldEntityText = null;
      this.formFieldData.formfieldEntityValue = null;
      this.changeValueEvEm.emit();
    }
  }
  updatePercentage(event) {
    const { value } = event.target;
    if (value !== '') {
      if (!this.checkDecimalFormat(value)) {
        return;
      }
      // this.formFieldData.formfieldEntityFloatText = value;
      this.formFieldData.formfieldEntityText = this.castToFloat4Decimal(value).toFixed(4);
      // this.formFieldData.formfieldEntityValue = this.castToFloat2Decimal(value); // converted during normalization
      this.changeValueEvEm.emit();
    } else {
      this.formFieldData.formfieldEntityFloatText = null;
      this.formFieldData.formfieldEntityText = null;
      this.formFieldData.formfieldEntityValue = null;
      this.changeValueEvEm.emit();
    }
  }

  updateCost(event) {
    const { value } = event.target;
    if (value !== '') {
      if (!this.checkDecimalFormat(value)) {
        return;
      }
      // this.formFieldData.formfieldEntityFloatText = value;
      this.formFieldData.formfieldEntityText = this.castToFloat4Decimal(value).toFixed(4);
      this.formFieldData.formfieldEntityValue = this.castToFloat4Decimal(value); // is really needed?
      this.changeValueEvEm.emit();
    } else {
      this.formFieldData.formfieldEntityFloatText = null;
      this.formFieldData.formfieldEntityText = null;
      this.formFieldData.formfieldEntityValue = null;
      this.changeValueEvEm.emit();
    }
  }

  updateRate(event) {
    const { value } = event.target;
    if (value !== '') {
      if (!this.checkDecimalFormat(value)) {
        return;
      }
      // this.formFieldData.formfieldEntityFloatText = value;
      this.formFieldData.formfieldEntityText = this.castToFloat6Decimal(value).toFixed(6);
      this.formFieldData.formfieldEntityValue = this.castToFloat6Decimal(value); // is really needed?
      this.changeValueEvEm.emit();
    } else {
      this.formFieldData.formfieldEntityFloatText = null;
      this.formFieldData.formfieldEntityText = null;
      this.formFieldData.formfieldEntityValue = null;
      this.changeValueEvEm.emit();
    }
  }

  private checkDecimalFormat(value: any): boolean {
    const splitted = value.replace(',', '.').split('.');
    if (splitted.length > 2) {
      // Too much decimal dots
      this.messageNotifierService.showWarningMessage(_('decimal_error_decimal_format'));
      return false;
    }
    const nOfDecimalDigits = splitted[1] ? splitted[1].length : 0;
    if (nOfDecimalDigits > this.formFieldData.formfieldEntityScale) {
      // Too much decimal digits
      this.messageNotifierService.showWarningMessage(_('decimal_error_decimal_digit'));
      return false;
    }
    if (splitted[0].length + nOfDecimalDigits > this.formFieldData.formfieldEntityPrecision) {
      // Number is bigger than valid precision
      this.messageNotifierService.showWarningMessage(_('decimal_error_precision'));
      return false;
    }
    return true;
  }

  isSameDate(originalDate, newDate): boolean {
    const original = moment(originalDate);
    const newD = moment(newDate);
    return original.isSame(newD, 'day');
  }

  updateDate(event) {
    this.formFieldData.formfieldEntityText = this.dateFormRequest(event);
    this.datepickerDirty =
      this.isDirtyEnabled && !this.isSameDate(this.originalFormFieldData.formfieldEntityText, this.formFieldData.formfieldEntityText);
    this.changeValueEvEm.emit();
  }

  isSameDatetime(originalDate, newDate): boolean {
    const original = moment(originalDate);
    const newD = moment(newDate);
    return original.isSame(newD, 'minute');
  }

  updateDatetime(event) {
    this.formFieldData.formfieldEntityText = this.datetimeCastUTC(event);
    this.datepickerDirty =
      this.isDirtyEnabled && !this.isSameDatetime(this.originalFormFieldData.formfieldEntityText, this.formFieldData.formfieldEntityText);
    this.changeValueEvEm.emit();
  }

  updateTime(event: string) {
    this.formFieldData.formfieldEntityText = event;
    this.changeValueEvEm.emit();
  }

  checkBitsetSelection(selection: MatListOption[]) {
    const selectedKeyValues = selection.map((s) => s.value as KeyValue<string, string>);
    const sum = selectedKeyValues.reduce((acc, cur) => acc + this.castToInt(cur.key), 0);
    const text = selectedKeyValues.map((kv) => kv.value).join(', ');
    this.formFieldData.formfieldEntityValue = sum !== 0 ? sum : 0;
    this.formFieldData.formfieldEntityText = sum !== 0 ? text : '';
    this.changeValueEvEm.emit();
  }

  checkBitsetSelectionByDropdown(selection: MatSelectChange) {
    const selectedKeyValues = selection.value.map((s) => s as KeyValue<string, string>);
    const sum = selectedKeyValues.reduce((acc, cur) => acc + this.castToInt(cur.key), 0);
    const text = selectedKeyValues.map((kv) => kv.value).join(', ');
    this.formFieldData.formfieldEntityValue = sum !== 0 ? sum : 0;
    this.formFieldData.formfieldEntityText = sum !== 0 ? text : '';
    this.changeValueEvEm.emit();
  }

  clearInput() {
    this.formFieldData.formfieldEntityText = null;
    this.formFieldData.formfieldEntityFloatText = null;
    this.formFieldData.formfieldEntityValue = null;
    this.formFieldData.filterStringOption = null;
    this.filterOptions(null);
    this.changeValueEvEm.emit();
  }

  bitwiseAnd(value: number, mask: number) {
    if (mask == null) {
      return false;
    }
    // eslint-disable-next-line no-bitwise
    return (value & mask) === value;
  }

  bitwiseAndDropdown(o1: any, o2: any) {
    return o1 && o2 ? o1.key === o2.key : o2 === o2;
  }

  readOnlyCheckbox() {
    return false;
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }

  onCkeditorReady(): void {
    // WORKAROUND - to fix ngModel binding does not set initial value https://github.com/ckeditor/ckeditor5-angular/issues/265
    if (this.ckeditor && this.formFieldData.formfieldEntityText) {
      setTimeout(() => {
        // this.ckeditor.editorInstance?.setData(this.formFieldData.formfieldEntityText);
      }, 0);
    }
  }

  onAddSingleTextPlaceholder(button: KeyValue<string, string>) {
    if (this.formFieldData.formfieldEntityText == null) {
      this.formFieldData.formfieldEntityText = '';
    }
    const el = document.getElementById('form-field-multiline-inputarea');
    insertTextAtCursor(el, button.key);
  }

  onAddMultiTextPlaceholder(button: KeyValue<string, string>) {
    if (this.formFieldData.formfieldEntityText == null) {
      this.formFieldData.formfieldEntityText = '';
    }
    const el = document.getElementById('form-field-multiline-textarea');
    insertTextAtCursor(el, button.key);
  }

  onAddCkeditorTextPlaceholder(button: KeyValue<string, string>) {
    this.ckeditor.editorInstance?.model.change((writer) => {
      const insertPosition = this.ckeditor.editorInstance?.model.document.selection.getLastPosition();
      writer.insertText(button.key, null, insertPosition);
    });
  }

  // MAIL TEMPLATE
  async loadMailTemplateOptions() {
    this.staticOptions = (await firstValueFrom(this.mailTemplateService.getMailTemplateStaticsOptions(this.entityKind))).data;
    this.selectedStage = this.staticOptions.stages && this.staticOptions.stages.length > 0 ? this.staticOptions.stages[0] : null;
    this.selectedLanguage =
      this.staticOptions.languages && this.staticOptions.languages.length > 0 ? this.staticOptions.languages[0] : null;
  }

  subjectBodyChanged() {
    this.formFieldData.formfieldEntityText = JSON.stringify(this.mailTemplateFrame);
    this.changeValueEvEm.emit();
  }

  selectedTemplateOptionChanged() {
    this.mailTemplateFrame.templateId = this.selectedTemplate.entityId;
    this.formFieldData.formfieldEntityText = JSON.stringify(this.mailTemplateFrame);
    this.changeValueEvEm.emit();
  }

  selectedFontfamilyOptionChanged() {
    this.mailTemplateFrame.fontfamilyId = this.selectedFontfamily.entityId;
    this.formFieldData.formfieldEntityText = JSON.stringify(this.mailTemplateFrame);
    this.changeValueEvEm.emit();
  }

  selectedOutgoingAccountOptionChanged() {
    this.mailTemplateFrame.mailoutgoingaccountId = this.selectedOutgoingAccount.entityId;
    this.formFieldData.formfieldEntityText = JSON.stringify(this.mailTemplateFrame);
    this.changeValueEvEm.emit();
  }

  selectedStageOptionChanged() {
    this.selectedMailTemplateFrameBody = this.mailTemplateFrame.bodies.find(
      (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === this.selectedStage.entityId
    );
    this.originalSelectedMailTemplateFrameBody = this.originalMailTemplateFrame.bodies.find(
      (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === b.stageId
    );
  }

  selectedLanguageOptionChanged() {
    this.selectedMailTemplateFrameBody = this.mailTemplateFrame.bodies.find(
      (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === this.selectedStage.entityId
    );
    this.originalSelectedMailTemplateFrameBody = this.originalMailTemplateFrame.bodies.find(
      (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === b.stageId
    );
  }

  showPreview() {
    this.dialog.open(MailPreviewModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'mail-preview-modal-dialog',
      disableClose: true,
      data: {
        mailframedefaultId: this.mailTemplateFrame.defaultId,
        mailframeId: this.mailTemplateFrame.id,
        mailtemplateId: this.mailTemplateFrame.templateId,
        stageId: this.selectedStage.entityId,
        languageId: this.selectedLanguage.entityId,
        fontfamilyId: this.selectedFontfamily.entityId,
        subject: this.selectedMailTemplateFrameBody.subject,
        body: this.selectedMailTemplateFrameBody.body,
      } as MailPreviewModalDialogData,
    });
  }

  onAddSingleTextMailPlaceholder(button: KeyValue<string, string>) {
    if (this.selectedMailTemplateFrameBody.subject == null) {
      this.selectedMailTemplateFrameBody.subject = '';
    }
    const el = document.getElementById('form-field-multiline-mail-inputarea');
    insertTextAtCursor(el, button.key);
  }

  onAddCkeditorTextMailPlaceholder(button: KeyValue<string, string>) {
    this.ckeditor.editorInstance?.model.change((writer) => {
      const insertPosition = this.ckeditor.editorInstance?.model.document.selection.getLastPosition();
      writer.insertText(button.key, null, insertPosition);
      this.ckeditor.editorInstance?.editing.view.focus();
    });
  }

  onCkeditorMailReady(): void {
    // WORKAROUND - to fix ngModel binding does not set initial value https://github.com/ckeditor/ckeditor5-angular/issues/265
    if (this.ckeditor && this.selectedMailTemplateFrameBody.body) {
      setTimeout(() => {
        // this.ckeditor.editorInstance?.setData(this.selectedMailTemplateFrameBody.body);
      }, 0);
    }
  }

  get FormFieldInputfieldBooleanDisplayOption() {
    return FormFieldInputfieldBooleanDisplayOption;
  }
  get FormFieldInputfieldOptionDisplayOption() {
    return FormFieldInputfieldOptionDisplayOption;
  }
  get GenericFormFieldRequiredType() {
    return GenericFormFieldRequiredType;
  }

  get Entities() {
    return Entities;
  }
}

import { CdkDropList } from '@angular/cdk/drag-drop';
import { KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { CompactType, DisplayGrid, GridType, GridsterConfig, GridsterItemComponent } from 'angular-gridster2';
import { ResizedEvent } from 'angular-resize-event';
import { Observable } from 'rxjs';
import { Entity, EntityOptionFilter } from 'src/app/models/entity';
import { ForeignEntity } from 'src/app/models/foreign-entity';
import { FormFieldConfigurationItem, FormFieldConfigurationRequest } from 'src/app/models/forms/form-configuration';
import { FormFieldConfigurationFieldKind, FormFieldConfigurationKind } from 'src/app/models/forms/form-configuration-kind';
import {
  FormFieldConfigurationFieldOptions,
  FormFieldInputfieldBooleanDisplayOption,
  FormFieldInputfieldOptionDisplayOption,
} from 'src/app/models/forms/form-configuration-option';
import { GenericFormFieldItem } from 'src/app/models/forms/form-field';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';
import {
  TranslationsDialogData,
  TranslationsModalDialogComponent,
} from '../../inputs/translations-modal-dialog/translations-modal-dialog.component';

@Component({
  selector: 'app-generic-form-configuration, generic-form-configuration',
  templateUrl: './generic-form-configuration.component.html',
  styleUrls: ['./generic-form-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenericFormConfigurationComponent extends FormatComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmCancelMenu') confirmCancelMenu: MatMenuTrigger;
  @Input() entitySubj: Observable<Entity>;
  @Input() isEditing: boolean;
  @Input() isFormFieldConfiguration = false;
  @Input() isTemplateFormFieldConfiguration = false;
  @Input() isProductFieldConfiguration = false;
  @Input() hideFlyingButtons = false;
  @Input() editModeAfterInit = false; // ISSUE #2365 feedback -  immediate start including controls and reshape their size.
  @Input() fieldlistAPIPath: string;
  @Input() getAPIPath: string;
  @Input() putAPIPath: string;
  @Input() postAPIPath: string;
  @Output() isEditingChange = new EventEmitter<boolean>();
  @ViewChild('formConfigGridster') el: CdkDropList;

  DIRTY_CLASSNAME = 'fo-dirty-behaviour';
  DIRTY_CHECKBOX_CLASSNAME = 'fo-dirty-behaviour-checkbox';

  enableConfirmCancel = false;
  selectedEntity: Entity;

  // Form configuration Gridster
  noFormFieldItems = false;
  options: GridsterConfig;
  readonly rowHeight = 20;
  readonly columnNumber = 48;
  readonly editModeCellMargin = 2;
  colWidth: number;

  formFieldConfigurationItems: FormFieldConfigurationItem[];
  originalConfigurationItems: FormFieldConfigurationItem[];
  formFieldConfigurationFieldKinds: FormFieldConfigurationFieldKind[];
  formFieldConfigurationFieldOptions: FormFieldConfigurationFieldOptions;
  // formFieldConfigurationForeignFilters: Entity[] = [];
  foreignEntityOptions: ForeignEntity[] = [];
  formFieldConfigurationOptionFilters: EntityOptionFilter[] = [];
  selectedItem: FormFieldConfigurationItem;
  selectedIndex: number;

  labelWidthOptions: number[] = [];
  precisionOptions: number[] = [9, 10, 11, 12, 13, 14];
  scaleOptions: number[] = [0, 1, 2, 3, 4, 5, 6];
  defaultValue: GenericFormFieldItem;
  currentRegion: string;
  THOUSANDS_SEPARATOR: string;

  originalTableTranslations: KeyValue<string, string>[];

  productFieldRemoveDialogRef: MatDialogRef<any>;
  productFieldToRemove: FormFieldConfigurationFieldKind;

  constructor(
    private dialog: MatDialog,
    private coreDataService: CoreDataService,
    private messageNotifierService: MessageNotifierService,
    protected cdRef: ChangeDetectorRef
  ) {
    super();
    this.store
      .select(PreferencesSelectors.selectPreferencesLanguageRegion)
      // Replace required for toLocaleString. Accept only Locale in format xx-XX
      .subscribe((langRegion) => (this.currentRegion = langRegion && langRegion.region ? langRegion.region.replace('_', '-') : 'en'));
    this.THOUSANDS_SEPARATOR = this.dateFormatterService.THOUSANDS_SEPARATOR;
  }

  ngAfterViewInit() {
    if (this.el && this.colWidth == null) {
      this.colWidth = Math.floor(this.el.element.nativeElement.clientWidth / this.columnNumber);
      this.options = this.getOptions(false);
      this.cdRef.detectChanges();
    }
  }

  gridsterContainerResized(event: ResizedEvent) {
    const width = event.newRect.width;
    const effectiveWidth = this.isEditing ? width - 2 * this.editModeCellMargin - (this.columnNumber - 1) * this.editModeCellMargin : width;
    const colWidthFloor = Math.floor(effectiveWidth / this.columnNumber);
    // LogService.debug(this, 'GenericFormComponent.gridsterContainerResized', 'width', effectiveWidth);
    // LogService.debug(this, 'GenericFormComponent.getOptions', 'colWidth', effectiveWidth / this.columnNumber);
    if (this.colWidth !== colWidthFloor) {
      this.colWidth = colWidthFloor;
      // LogService.debug(this, 'GenericFormComponent.getOptions', 'colWidthFloor', this.colWidth);
      this.options.fixedColWidth = this.colWidth;
      this.options.api.optionsChanged();
      this.cdRef.detectChanges();
    }
  }

  static itemResize(item: FormFieldConfigurationItem, itemComponent: GridsterItemComponent) {
    if (itemComponent.resize.resizeEnabled) {
      if (itemComponent.$item.cols !== item.formfieldW) {
        console.log('item width resized', item, itemComponent);
        item.formfieldW = itemComponent.$item.cols;
        item.isDirty = true;
      }
      if (itemComponent.$item.rows !== item.formfieldH) {
        console.log('item height resized', item, itemComponent);
        item.formfieldH = itemComponent.$item.rows;
        item.isDirty = true;
      }
    }
  }

  ngOnInit(): void {
    this.defaultValue = new GenericFormFieldItem(null, this.currentRegion, this.THOUSANDS_SEPARATOR);
    this.subscribe(this.entitySubj, (entity) => {
      if (entity) {
        this.selectedEntity = entity;
        this.loadFormConfigurationOptions();
      }
    });
  }

  get availableFormFieldConfigurationFieldKinds() {
    if (this.formFieldConfigurationFieldKinds) {
      return this.formFieldConfigurationFieldKinds.filter((fk) => {
        if (fk.formfieldkindMultiuseable) {
          return true;
        }
        const item = this.formFieldConfigurationItems.find(
          (ffci) => ffci.formfieldKindId === fk.formfieldkindId && ffci.formfieldEntityColumnId === fk.formfieldkindEntityColumnId
        );
        if (item) {
          if (item.formfieldRecordDeleted) {
            return false;
          }
          return item.formfieldRemoved || (item.formfieldW === 0 && item.formfieldH === 0);
        } else {
          return false;
        }
      });
    } else {
      return [];
    }
  }

  isFormFieldConfigurationEnabled(item: FormFieldConfigurationItem) {
    return !item.formfieldRemoved && item.formfieldW !== 0 && item.formfieldH !== 0;
  }

  loadFormConfigurationOptions() {
    this.subscribe(this.coreDataService.getFormFieldConfigurationFieldKinds(this.fieldlistAPIPath), ({ data }) => {
      if (data && data.length > 0) {
        this.formFieldConfigurationFieldKinds = data;
        this.loadFormFieldConfiguration(true);
      } else {
        this.formFieldConfigurationFieldKinds = [];
        this.noFormFieldItems = true;
      }
    });
  }

  loadFormFieldConfiguration(isInit: boolean) {
    this.subscribe(this.coreDataService.getFormFieldConfiguration(this.getAPIPath), ({ data }) => {
      if (data) {
        this.formFieldConfigurationFieldOptions = data.options;
        if (data.fields && data.fields.length > 0) {
          this.formFieldConfigurationItems = data.fields.map(
            (ffc) =>
              new FormFieldConfigurationItem(
                ffc,
                data.options,
                this.formFieldConfigurationFieldKinds.find(
                  (ffco) => ffc.formfieldKindId === ffco.formfieldkindId && ffc.formfieldEntityColumnId === ffco.formfieldkindEntityColumnId
                )
              )
          );
          this.setHiddenUniqueField();
        } else {
          this.formFieldConfigurationItems = [];
          this.noFormFieldItems = true;
        }
        LogService.debug(this, this.loadFormFieldConfiguration.name, 'Form Configuration Items', this.formFieldConfigurationItems);
      }
      if (isInit && this.editModeAfterInit) {
        this.goEditMode(); // ISSUE #2365 feedback -  immediate start including controls and reshape their size.
      }
    });
  }

  private setHiddenUniqueField() {
    this.formFieldConfigurationItems
      .filter(
        (ffc) =>
          (ffc.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
            ffc.formfieldKindId === FormFieldConfigurationKind.CHIPS ||
            ffc.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL) &&
          ffc.formfieldEntityColumnId != null &&
          (ffc.formfieldW === 0 || ffc.formfieldH === 0)
      )
      .forEach((ffc) => {
        const kind = this.formFieldConfigurationFieldKinds.find(
          (fk) => !fk.formfieldkindMultiuseable && fk.formfieldkindEntityColumnId === ffc.formfieldEntityColumnId
        );
        if (kind) {
          kind.formfieldkindField = ffc;
        }
      });
  }

  loadForeignOptionFilters() {
    const entityKind = this.selectedItem.formfieldEntityKindId
      ? this.selectedItem.options.formfieldoptionSearchEntityKindOptions.find(
          (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityKindId
        )?.value
      : null;
    if (entityKind) {
      // this.subscribe(
      //   this.coreDataService.getEntitiesForeignFilters(entityKind),
      //   ({ data }) => (this.formFieldConfigurationForeignFilters = data ? [{ ...new Entity(), entityId: null }, ...data] : [])
      // );
      this.subscribe(
        this.coreDataService.getEntitiesOptionFilters(entityKind),
        ({ data }) =>
          (this.formFieldConfigurationOptionFilters = data ? [{ ...new EntityOptionFilter(), entityoptionfilterId: null }, ...data] : [])
      );
    }
  }

  openRegexTranslationsDialog() {
    // this.initForm();
    this.originalTableTranslations = JSON.parse(JSON.stringify(this.selectedItem.formfieldEntityRegexerrormsgTranslations));
    this.dialog
      .open(TranslationsModalDialogComponent, {
        autoFocus: false,
        disableClose: true,
        width: '800px',
        data: {
          keyvaluesTranslations: this.selectedItem.formfieldEntityRegexerrormsgTranslations,
          originalTranslations: this.originalTableTranslations,
          isViewMode: !this.isEditing,
          isDirtyEnabled: true,
        } as TranslationsDialogData,
      })
      .afterClosed()
      .subscribe((translations) => {
        if (translations) {
          this.selectedItem.formfieldEntityRegexerrormsgTranslations = JSON.parse(JSON.stringify(translations));
        } else {
          this.selectedItem.formfieldEntityRegexerrormsgTranslations = JSON.parse(JSON.stringify(this.originalTableTranslations));
        }
      });
  }

  // setFilterForeignKind() {
  //   const foreign = this.selectedItem.formfieldFilterForeignKindId
  //     ? this.formFieldConfigurationForeignFilters.find((f) => f.entityId === this.selectedItem.formfieldFilterForeignKindId)
  //     : null;
  //   this.selectedItem.formfieldFilterForeignKind = foreign ? foreign.entityName : null;
  //   this.selectedItem.formfieldFilterForeignId = null;
  //   this.selectedItem.formfieldFilterForeignText = null;
  //   this.foreignEntityOptions = [];
  //   this.selectedItem.formfieldFilterOptionId = null;
  //   this.selectedItem.formfieldFilterOptionColumnId = null;
  // }

  // setFilterOption2Column() {
  //   this.selectedItem.formfieldFilterForeignId = null;
  //   this.selectedItem.formfieldFilterForeignText = null;
  //   this.selectedItem.formfieldFilterForeignKindId = null;
  //   this.selectedItem.formfieldFilterForeignKind = null;
  // }

  isOption1FilterDirty() {
    if (this.selectedItem.original != null) {
      return (
        JSON.stringify(this.selectedItem.formfieldFilterOption1Values) !==
        JSON.stringify(this.selectedItem.original.formfieldFilterOption1Values)
      );
    } else {
      return false;
    }
  }

  isOption2FilterDirty() {
    if (this.selectedItem.original != null) {
      return (
        JSON.stringify(this.selectedItem.formfieldFilterOption2Values) !==
        JSON.stringify(this.selectedItem.original.formfieldFilterOption2Values)
      );
    } else {
      return false;
    }
  }

  get entityoptionfilterOptions1() {
    if (this.selectedItem.formfieldFilterOption1ColumnId) {
      const options = this.formFieldConfigurationOptionFilters.find(
        (o) => o.entityoptionfilterId === this.selectedItem.formfieldFilterOption1ColumnId
      );
      return options ? options.entityoptionfilterOptions : [];
    }
    return [];
  }

  get entityoptionfilterOptions2() {
    if (this.selectedItem.formfieldFilterOption2ColumnId) {
      const options = this.formFieldConfigurationOptionFilters.find(
        (o) => o.entityoptionfilterId === this.selectedItem.formfieldFilterOption2ColumnId
      );
      return options ? options.entityoptionfilterOptions : [];
    }
    return [];
  }

  formfieldFilterOption1Change($event) {
    this.selectedItem.formfieldFilterOption1Values = $event;
  }

  formfieldFilterOption2Change($event) {
    this.selectedItem.formfieldFilterOption2Values = $event;
  }

  setSelectedItem(item: FormFieldConfigurationItem, index: number) {
    this.selectedItem = null;
    this.cdRef.detectChanges();
    this.labelWidthOptions = Array.from({ length: item.cols - 1 }, (_, i) => i);
    this.selectedItem = item;
    this.loadForeignOptionFilters();
    this.initDefaultValue();
    this.selectedIndex = index;
    this.cdRef.detectChanges();
    this.options.api.resize();
  }

  deselectItem() {
    this.selectedItem = null;
    this.cdRef.detectChanges();
    this.options.api.resize();
  }

  isDirty() {
    if (this.formFieldConfigurationItems && this.originalConfigurationItems) {
      // return JSON.stringify(this.formFieldConfigurationItems) !== JSON.stringify(this.originalConfigurationItems);
      return this.formFieldConfigurationItems.some((ffci) => ffci.isDirty || ffci.formfieldRemoved);
    } else {
      return false;
    }
    // return false;
  }

  isValid() {
    return true;
    /*
    return (
      this.formFieldConfigurationItems.filter((ffci) => {
        return ffci.formfieldName == null || ffci.formfieldName === '';
      }).length === 0
    );
    */
  }

  isFormFieldStringColumn() {
    const columnOption = this.selectedItem.options.formfieldoptionEntityColumnOptions.find(
      (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityColumnId
    );
    return columnOption && columnOption.addon === 'string';
  }

  isFormFieldBitsetColumn() {
    const columnOption = this.selectedItem.options.formfieldoptionEntityColumnOptions.find(
      (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityColumnId
    );
    return columnOption && columnOption.addon === 'bitset';
  }

  isFormFieldOptionColumn() {
    const columnOption = this.selectedItem.options.formfieldoptionEntityColumnOptions.find(
      (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityColumnId
    );
    return columnOption && columnOption.addon === 'option';
  }

  isFormFieldImageColumn() {
    const columnOption = this.selectedItem.options.formfieldoptionEntityColumnOptions.find(
      (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityColumnId
    );
    return columnOption && columnOption.addon === 'image';
  }
  /** GRIDSTER CONFIGURATION */

  private getOptions(isEditMode: boolean): GridsterConfig {
    const m = this.getOptions.name;

    const retVal: GridsterConfig = {
      displayGrid: isEditMode ? DisplayGrid.Always : DisplayGrid.None,
      gridType: isEditMode ? GridType.VerticalFixed : GridType.Fixed,
      compactType: CompactType.CompactUp,
      resizable: { enabled: isEditMode },
      draggable: { enabled: isEditMode, dropOverItems: false },
      pushItems: true,
      swap: false,
      swapWhileDragging: false,
      disablePushOnDrag: true,
      disablePushOnResize: false,
      disableScrollHorizontal: true,
      minCols: this.columnNumber,
      maxCols: this.columnNumber,
      maxItemCols: this.columnNumber,
      fixedRowHeight: this.rowHeight,
      fixedColWidth: this.colWidth,
      setGridSize: false,
      outerMargin: isEditMode,
      margin: isEditMode ? this.editModeCellMargin : 0,
      itemResizeCallback: GenericFormConfigurationComponent.itemResize,
      itemChangeCallback(item, itemComponent) {
        item.isDirty = true;
      },
    };

    return retVal;
  }

  /** BUTTONS */

  goEditMode() {
    this.isEditingChange.emit(true);
    this.isEditing = true;
    this.options = this.getOptions(true);
    this.originalConfigurationItems = JSON.parse(JSON.stringify(this.formFieldConfigurationItems));
    setTimeout(() => {
      this.cdRef.detectChanges();
      this.options.api.resize();
    }, 10);
  }

  onSave() {
    LogService.debug(this, this.onSave.name, 'Request', this.formFieldConfigurationItems);
    this.subscribe(
      this.coreDataService.putFormFieldConfiguration(
        this.putAPIPath,
        this.formFieldConfigurationItems
          .filter((ffci) => !ffci.formfieldRemoved || (ffci.formfieldRemoved && ffci.formfieldId !== -1))
          .map((ffci) => {
            return new FormFieldConfigurationRequest(ffci);
          })
      ),
      (response) => {
        LogService.debug(this, this.onSave.name, LogService.RESPONSE, response);
        if (response.data) {
          if (response.data.state) {
            // Success
            this.messageNotifierService.showSuccessMessage(_('form_configuration_update_success'));
            this.subscribe(this.translate.reloadLang(this.translate.currentLang), () => {
              this.loadFormFieldConfiguration(false);
              this.isEditingChange.emit(false);
              this.options = this.getOptions(false);
              this.selectedItem = null;
              this.cdRef.detectChanges();
            });
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          }
        }
      }
    );
  }

  onCancel($event) {
    if (this.isDirty()) {
      this.enableConfirmCancel = true;
      this.confirmCancelMenu.openMenu();
      this.cdRef.detectChanges();
      // this.confirmCancelButton.focus();
      $event.stopPropagation();
    } else {
      this.onConfirmCancel();
    }
  }

  onConfirmCancel() {
    this.enableConfirmCancel = false;
    this.isEditingChange.emit(false);
    this.formFieldConfigurationItems = JSON.parse(JSON.stringify(this.originalConfigurationItems));
    this.options = this.getOptions(false);
    this.selectedItem = null;
  }

  get FormFieldConfigurationKind() {
    return FormFieldConfigurationKind;
  }

  removeItem(item: FormFieldConfigurationItem) {
    item.formfieldRemoved = true;
    const kind = this.formFieldConfigurationFieldKinds.find(
      (fk) => !fk.formfieldkindMultiuseable && fk.formfieldkindEntityColumnId === item.formfieldEntityColumnId
    );
    if (kind) {
      kind.formfieldkindField = item;
    }
  }

  addGridsterItem(itemKind: FormFieldConfigurationFieldKind) {
    const isText = itemKind.formfieldkindId === FormFieldConfigurationKind.TEXT;
    const isSearch = itemKind.formfieldkindId === FormFieldConfigurationKind.SEARCH;
    const isEntityField = itemKind.formfieldkindId === FormFieldConfigurationKind.ENTITY_FIELD;
    const isInputField = itemKind.formfieldkindId === FormFieldConfigurationKind.INPUT_FIELD;
    const isChipField = itemKind.formfieldkindId === FormFieldConfigurationKind.CHIPS;
    if (itemKind.formfieldkindField != null) {
      this.restoreItem(itemKind);
    } else {
      this.createNewItemFromKind(itemKind, isText, isEntityField, isInputField, isSearch, isChipField);
    }
  }

  removeProductFieldDialog(ref: TemplateRef<any>, itemKind: FormFieldConfigurationFieldKind) {
    this.productFieldRemoveDialogRef = this.dialog.open(ref, { autoFocus: false, width: '600px', hasBackdrop: true, disableClose: true });
    this.productFieldToRemove = itemKind;
  }

  onProductFieldConfirmRemove() {
    this.productFieldToRemove.formfieldkindField.formfieldRecordDeleted = true;
    this.productFieldRemoveDialogRef.close();
  }

  onProductFieldRemoveClose() {
    this.productFieldRemoveDialogRef.close();
  }

  private restoreItem(itemKind: FormFieldConfigurationFieldKind) {
    if (itemKind.formfieldkindField.rows && itemKind.formfieldkindField.cols) {
      itemKind.formfieldkindField.formfieldH = itemKind.formfieldkindField.rows;
      itemKind.formfieldkindField.formfieldW = itemKind.formfieldkindField.cols;
    } else {
      itemKind.formfieldkindField.formfieldH = itemKind.formfieldkindDefaultH;
      itemKind.formfieldkindField.rows = itemKind.formfieldkindDefaultH;
      itemKind.formfieldkindField.formfieldW = this.columnNumber;
      itemKind.formfieldkindField.cols = this.columnNumber;
    }
    // itemKind.formfieldkindField.formfieldH = itemKind.formfieldkindField.rows
    //   ? itemKind.formfieldkindField.rows
    //   : itemKind.formfieldkindMinH;
    // itemKind.formfieldkindField.formfieldW = itemKind.formfieldkindField.cols ? itemKind.formfieldkindField.cols : this.columnNumber;
    const newPosition = this.options.api.getFirstPossiblePosition(itemKind.formfieldkindField);
    itemKind.formfieldkindField.x = newPosition.x;
    itemKind.formfieldkindField.y = newPosition.y;
    itemKind.formfieldkindField.formfieldRemoved = false;
    itemKind.formfieldkindField.isDirty = true;
  }

  private createNewItemFromKind(
    itemKind: FormFieldConfigurationFieldKind,
    isText: boolean,
    isEntityField: boolean,
    isInputField: boolean,
    isSearch: boolean,
    isChipField: boolean
  ) {
    let newItem: FormFieldConfigurationItem = {
      formfieldId: -1,
      formfieldKindId: itemKind.formfieldkindId,
      formfieldKind: itemKind.formfieldkindKind,
      formfieldTextname: _('formfield_textname_new'),
      formfieldTextnameTranslations: this.createNewFieldTranslations(this.translate.instant('formfield_textname_new')),
      formfieldInfotextname: null,
      formfieldInfotextnameTranslations: this.createNewFieldTranslations(null),
      formfieldLabelShow: true,
      formfieldLabelDisplay: true,
      // Text
      formfieldTextKindId: isText ? this.castToInt(this.formFieldConfigurationFieldOptions.formfieldoptionTextKindOptions[0].key) : null,
      formfieldTextKind: isText ? this.formFieldConfigurationFieldOptions.formfieldoptionTextKindOptions[0].value : null,
      formfieldTextSizeId: isText ? this.castToInt(this.formFieldConfigurationFieldOptions.formfieldoptionTextSizeOptions[0].key) : null,
      formfieldTextSize: isText ? this.formFieldConfigurationFieldOptions.formfieldoptionTextSizeOptions[0].value : null,
      formfieldTextWeightId: isText
        ? this.castToInt(this.formFieldConfigurationFieldOptions.formfieldoptionTextWeightOptions[0].key)
        : null,
      formfieldTextWeight: isText ? this.formFieldConfigurationFieldOptions.formfieldoptionTextWeightOptions[0].value : null,
      // Entity Field
      formfieldEntityColumnId: isEntityField
        ? this.castToInt(this.formFieldConfigurationFieldOptions.formfieldoptionEntityColumnOptions[0].key)
        : null,
      formfieldEntityColumn: isEntityField ? this.formFieldConfigurationFieldOptions.formfieldoptionEntityColumnOptions[0].value : null,
      formfieldRequired: 0,
      formfieldHideId: 0,
      formfieldHide: null,
      // Input Field
      formfieldEntityType: isInputField
        ? this.formFieldConfigurationFieldOptions.formfieldoptionEntityTypeOptions[0].value
        : isEntityField
        ? this.formFieldConfigurationFieldOptions.formfieldoptionEntityColumnOptions[0].addon
        : null,
      formfieldEntityTypeId: isInputField
        ? this.castToInt(this.formFieldConfigurationFieldOptions.formfieldoptionEntityTypeOptions[0].key)
        : null,
      formfieldEntityText: null,
      formfieldEntityValue: null,
      formfieldEntityPrecision: isInputField ? 1 : null,
      formfieldEntityScale: null,
      formfieldEntitySpecification: null,
      formfieldEntityOption: isInputField
        ? this.formFieldConfigurationFieldOptions.formfieldoptionInputOptionOptions[0].key
        : isEntityField
        ? this.formFieldConfigurationFieldOptions.formfieldoptionEntityColumnOptions[0].addon2
        : null,
      formfieldEntityKind: isEntityField ? this.formFieldConfigurationFieldOptions.formfieldoptionEntityColumnOptions[0].addon2 : null,
      formfieldEntityKindId: isSearch
        ? this.castToInt(this.formFieldConfigurationFieldOptions.formfieldoptionSearchEntityKindOptions[0].key)
        : null,
      formfieldEntityRelatedKind: null,
      formfieldEntityRelatedKindId: null,
      formfieldEntityOtherKind: null,
      formfieldEntityOtherKindId: null,
      formfieldEntityRegex: null,
      formfieldEntityRegexerrormsg: null,
      formfieldEntityRegexerrormsgTranslations: this.createNewFieldTranslations(null),
      formfieldEntityUnit: null,
      formfieldEntityUnitTranslations: this.createNewFieldTranslations(null),
      // formfieldFilterForeignId: null,
      // formfieldFilterForeignKind: null,
      // formfieldFilterForeignKindId: null,
      // formfieldFilterForeignText: null,
      // formfieldFilterOptionColumn: null,
      // formfieldFilterOptionColumnId: null,
      // formfieldFilterOptionId: null,
      // formfieldFilterOptionText: null,
      formfieldFilterOption1Column: null,
      formfieldFilterOption1ColumnId: null,
      formfieldFilterOption1Values: null,
      formfieldFilterOption2Column: null,
      formfieldFilterOption2ColumnId: null,
      formfieldFilterOption2Values: null,
      formfieldDisplayType: isInputField
        ? this.defaultValue.formfieldEntityType === 'boolean'
          ? this.castToInt(this.formFieldConfigurationFieldOptions.formfieldoptionDisplayBooleanTypeOptions[0].key)
          : this.defaultValue.formfieldEntityType === 'option' || this.defaultValue.formfieldEntityType === 'bitset'
          ? this.castToInt(this.formFieldConfigurationFieldOptions.formfieldoptionDisplayOptionTypeOptions[0].key)
          : null
        : isChipField
        ? this.castToInt(this.formFieldConfigurationFieldOptions.formfieldoptionDisplayRelationTypeOptions[0].key)
        : null,
      // Hide On - Readonly On
      formfieldHideonfield: null,
      formfieldHideontrue: false,
      formfieldHideoncompact: false, // true Hide, false Keep
      formfieldReadonlyonfield: null,
      formfieldReadonlyontrue: false,
      // Grid
      formfieldX: 0,
      formfieldY: 0,
      formfieldW: this.columnNumber,
      formfieldH: itemKind.formfieldkindDefaultH,
      formfieldRemoved: false,
      formfieldRecordDeleted: false,
      x: 0,
      y: 0,
      cols: this.columnNumber,
      rows: itemKind.formfieldkindDefaultH,
      minItemRows: itemKind.formfieldkindMinH,
      options: this.formFieldConfigurationFieldOptions,
      original: null,
      isDirty: true,
    };

    newItem = { ...newItem, ...this.options.api.getFirstPossiblePosition(newItem), original: JSON.parse(JSON.stringify(newItem)) };
    this.formFieldConfigurationItems.push(newItem);
    this.cdRef.detectChanges();
  }

  createNewFieldTranslations(newLabel: string): KeyValue<string, string>[] {
    const newTranslations: KeyValue<string, string>[] = [];
    const appLang = this.translate.currentLang;
    this.formFieldConfigurationFieldOptions.formfieldoptionLanguageOptions.forEach((lang) => {
      const keyValue: KeyValue<string, string> = { key: lang.value, value: lang.value === appLang ? newLabel : null };
      newTranslations.push(keyValue);
    });
    return newTranslations;
  }

  defaultValueEnabled(): boolean {
    if (this.defaultValue && this.defaultValue.formfieldEntityType === 'foreign') {
      return this.defaultValue.formfieldEntityKind !== null && this.defaultValue.formfieldEntityKind !== undefined;
    } else if (this.defaultValue && this.defaultValue.formfieldEntityType === 'option') {
      return this.defaultValue && this.defaultValue.formfieldEntityOptions != null;
    } else {
      return this.defaultValue && this.defaultValue.formfieldEntityType !== null && this.defaultValue.formfieldEntityType !== undefined;
    }
  }

  setEntityFieldColum() {
    const selectedKeyvalue = this.selectedItem.options.formfieldoptionEntityColumnOptions.find(
      (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityColumnId
    );
    this.selectedItem.formfieldEntityColumn = selectedKeyvalue.value;
    this.selectedItem.formfieldEntityType = selectedKeyvalue.addon;
    this.selectedItem.formfieldEntityOption = selectedKeyvalue.addon2;
    this.selectedItem.formfieldEntityKind = selectedKeyvalue.addon2;
    this.initDefaultValue();
    // this.setDirtyFlag();
  }

  setChipsEntityRelatedKind() {
    const selectedKeyvalue = this.selectedItem.options.formfieldoptionRelatedEntityKindOptions.find(
      (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityRelatedKindId
    );
    this.selectedItem.formfieldEntityRelatedKind = selectedKeyvalue.value;
    // this.setDirtyFlag();
  }

  setChipsOtherKind() {
    const selectedKeyvalue = this.selectedItem.options.formfieldoptionRelatedEntityKindOptions.find(
      (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityOtherKindId
    );
    this.selectedItem.formfieldEntityRelatedKind = selectedKeyvalue.value;
    // this.setDirtyFlag();
  }

  initDefaultValue() {
    this.defaultValue.formfieldEntityText = null;
    this.defaultValue.formfieldEntityValue = null;
    this.defaultValue.formfieldEntityType = null;
    this.cdRef.detectChanges();
    this.defaultValue.formfieldEntityType =
      this.selectedItem.formfieldEntityType === 'date' || this.selectedItem.formfieldEntityType === 'datetime'
        ? 'option'
        : this.selectedItem.formfieldEntityType;
    this.defaultValue.formfieldEntityKind = this.selectedItem.formfieldEntityKind;
    this.defaultValue.formfieldEntityText = this.selectedItem.formfieldEntityText;
    this.defaultValue.formfieldEntityValue = this.selectedItem.formfieldEntityValue;
    this.defaultValue.formfieldDisplayType =
      this.defaultValue.formfieldEntityType === 'boolean' ? FormFieldInputfieldBooleanDisplayOption.CHECKBOX : null;
    this.defaultValue.formfieldDisplayType =
      this.defaultValue.formfieldEntityType === 'option' || this.defaultValue.formfieldEntityType === 'bitset'
        ? FormFieldInputfieldOptionDisplayOption.COMBOBOX
        : this.selectedItem.formfieldDisplayType;
    if (this.selectedItem.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD) {
      this.defaultValue.formfieldEntityOptions = this.selectedItem.formfieldEntityOption
        ? this.selectedItem.options.formfieldoptionInputOptionOptions.find((kv) => kv.key === this.selectedItem.formfieldEntityOption)?.data
        : null;
    }
    if (this.selectedItem.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD) {
      this.defaultValue.formfieldEntityOptions = this.selectedItem.formfieldEntityOption
        ? this.selectedItem.options.formfieldoptionEntityOptionOptions.find((kv) => kv.key === this.selectedItem.formfieldEntityOption)
            ?.data
        : null;
    }
    if (this.selectedItem.formfieldEntityType === 'date' || this.selectedItem.formfieldEntityType === 'datetime') {
      this.defaultValue.formfieldEntityOptions = this.selectedItem.options.formfieldoptionEntityDateOptions.map((kv) => {
        return { key: kv.key, value: kv.value, addon: null };
      });
    }
    this.cdRef.detectChanges();
  }

  defaultValueChanged() {
    console.log(this.selectedItem);
    this.selectedItem.formfieldEntityText = this.defaultValue.formfieldEntityText;
    this.selectedItem.formfieldEntityValue = this.defaultValue.formfieldEntityValue;
  }

  setInputFieldType() {
    const selectedKeyvalue = this.selectedItem.options.formfieldoptionEntityTypeOptions.find(
      (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityTypeId
    );
    this.selectedItem.formfieldEntityType = selectedKeyvalue.value;
    this.defaultValue.formfieldEntityType = selectedKeyvalue.value;
    this.initDefaultValue();
    // this.setDirtyFlag();
  }

  setInputFieldForeign() {
    const selectedKeyvalue = this.selectedItem.options.formfieldoptionEntityKindOptions.find(
      (kv) => this.castToInt(kv.key) === this.selectedItem.formfieldEntityKindId
    );
    this.selectedItem.formfieldEntityKind = selectedKeyvalue.value;
    this.defaultValue.formfieldEntityKind = selectedKeyvalue.value;
    this.initDefaultValue();
    // this.setDirtyFlag();
  }

  setInputFieldOption() {
    this.defaultValue.formfieldEntityOptions = null;
    this.cdRef.detectChanges();
    const selectedKeyvalue = this.selectedItem.options.formfieldoptionInputOptionOptions.find(
      (kv) => kv.key === this.selectedItem.formfieldEntityOption
    );
    this.defaultValue.formfieldEntityOptions = selectedKeyvalue.data;
    this.initDefaultValue();
  }
}

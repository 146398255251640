import { CollectionViewer, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantDataService } from '../services/tenant-data.service';
import { Entity } from './entity';
import { MailTemplateFrame } from './mailtemplate/mail-template-particular';
import { PaginatedRequest } from './paginated-request';
import { GenericTreeNode } from './tree-view-flat-node';

export class TenantTreeNode extends GenericTreeNode {
  tenantId: number;
  tenantKindId: number;
  tenantKindEntityId: number;
  tenantName: string;
  tenantCurrency: string;
  tenantCurrencyId: number;
  tenantAccountingtenant: string;
  tenantStartmonth: number;
  tenantChargebackInterface: string;
  tenantFinanceInterface: string;
  tenantVat: number;
}

export class TenantPeriod extends Entity {
  tenantperiodDescription: string;
  tenantperiodEnds: string;
  tenantperiodId: number;
  tenantperiodStarts: string;
  tenantperiodTenant: string;
  tenantperiodTenantId: number;
  tenantperiodTenantLedger: string;
  tenantperiodTenantLedgerCharging: string;
  tenantperiodTenantLedgerChargingId: number;
  tenantperiodTenantLedgerId: number;
  tenantperiodInvoicesCount: number;
  tenantperiodInvoicesSent: number;
}

export class TenantInvoice extends Entity {
  tenantinvoiceEnds: Date;
  tenantinvoiceId: number;
  tenantinvoiceMessage: string;
  tenantinvoiceSent: Date;
  tenantinvoiceSentBy: string;
  tenantinvoiceSentCc: string;
  tenantinvoiceSentTo: string;
  tenantinvoiceStarts: Date;
  tenantinvoiceTenant: string;
  tenantinvoiceTenantId: number;
  tenantinvoiceTenantLedger: string;
  tenantinvoiceTenantLedgerCharging: string;
  tenantinvoiceTenantLedgerChargingId: number;
  tenantinvoiceTenantLedgerId: number;
  tenantinvoiceEmailHistoryId: number;
  tenantinvoiceMailReplytoAddress: string;
  tenantinvoiceMailReplytoName: string;
  tenantinvoiceMailSender: {
    address: string;
    group: boolean;
    personal: string;
    type: string;
  };
  tenantinvoiceMailSenderAddress: string;
  tenantinvoiceMailSenderName: string;
  tenantinvoiceMailframe: MailTemplateFrame;
  tenantinvoiceMailframeId: number;
  tenantinvoicePeriod: string;
  tenantinvoiceStylesheetFileId: number;
  tenantinvoiceTargetLanguageCode: string;
  tenantinvoiceTargetTenant: string;
  tenantinvoiceTargetTenantId: number;
  tenantinvoiceTenantPeriod: string;
  tenantinvoiceTenantPeriodId: number;
}

export class TenantInvoiceRequest {
  tenantinvoiceEnds: string;
  tenantinvoiceStarts: string;
  tenantinvoiceTenantledgerchargingId: number;
  tenantinvoiceTenantperiodId: number;
}

export class TenantPeriodRequest {
  tenantperiodDesciption: string;
  tenantperiodEnds: string;
  tenantperiodStarts: string;
  tenantperiodTenantledgerId: number;
}

export class TenantInvoiceParams {
  public static FILTER_USER_IDS = 'filterUserIds';
}

export class TenantDynamicFlatNode {
  constructor(
    public item: TenantTreeNode,
    public level = 1,
    public expandable = false,
    public isLoading = false,
    public isIndeterminate = false,
    parentId?: number,
  ) {
    this.item.parentId = parentId;
  }
}

@Injectable()
export class TenantDynamicDataSource {
  dataChange = new BehaviorSubject<TenantDynamicFlatNode[]>([]);
  nodeSelection = new SelectionModel<TenantTreeNode>(true, []);
  baseApiPath: string;
  nodeName: string;
  isManualLoading = false;
  disableToggle = false;

  get data(): TenantDynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: TenantDynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private treeControl: FlatTreeControl<TenantDynamicFlatNode>,
    private database: TenantDynamicDatabase,
    private tenantDataService: TenantDataService,
    private request: PaginatedRequest,
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<TenantDynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if ((change as SelectionChange<TenantDynamicFlatNode>).added || (change as SelectionChange<TenantDynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<TenantDynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(): void {}

  handleTreeControl(change: SelectionChange<TenantDynamicFlatNode>) {
    if (change.added && !this.isManualLoading) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed && !this.isManualLoading) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  toggleNode(node: TenantDynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);

    node.isLoading = true;

    if (expand) {
      const childs = this.database.getChildren(node.item);
      if (childs) {
        const nodes = childs.map(
          (item) => new TenantDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId),
        );
        this.data.splice(index + 1, 0, ...nodes);
        // notify the change
        this.dataChange.next(this.data);
        node.isLoading = false;
      } else {
        this.tenantDataService.getTenants(this.request, node.item.tenantId, node.item.tenantKindId).subscribe(({ data }) => {
          if (data) {
            const nodes = data.map(
              (item) => new TenantDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId),
            );
            if (this.isNodeSelected(node)) {
              this.nodeSelection.select(...data);
            }
            this.database.setChildren(node.item, data);
            this.data.splice(index + 1, 0, ...nodes);
            // notify the change
            this.dataChange.next(this.data);
          } else {
            node.expandable = false;
          }
          node.isLoading = false;
        });
      }
    } else {
      let count = 0;
      // eslint-disable-next-line no-empty
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }
  }

  async refreshChildrenOfParentNode(item: TenantTreeNode) {
    const parentNode = this.data.find((n) => n.item.entityId === item.entityId && n.item.entityKind === item.entityKind);
    if (this.treeControl.isExpanded(parentNode)) {
      const index = this.data.indexOf(parentNode);
      const childs = await this.fetchChilds(parentNode);
      if (childs) {
        this.database.setChildren(parentNode.item, childs);
        const nodes = childs.map(
          (item) =>
            new TenantDynamicFlatNode(item, parentNode.level + 1, this.database.isExpandable(item), false, false, parentNode.item.entityId),
        );
        let count = 0;
        // eslint-disable-next-line no-empty
        for (let i = index + 1; i < this.data.length && this.data[i].level > parentNode.level; i++, count++) {}
        this.data.splice(index + 1, count, ...nodes);
        parentNode.item.entityChilds = nodes.length;
        parentNode.expandable = nodes.length > 0;
        // notify the change
        this.dataChange.next(this.data);
        this.disableToggle = true;
        await this.expandAllChildNodes(nodes);
        this.disableToggle = false;
      }
    } else {
      this.database.removeAllChildren(parentNode.item);
      parentNode.expandable = true;
      parentNode.item.entityChilds = item.entityChilds + 1;
      this.treeControl.toggle(parentNode);
    }
  }

  async fetchChilds(node: TenantDynamicFlatNode): Promise<TenantTreeNode[]> {
    const { data } = await firstValueFrom(this.tenantDataService.getTenants(this.request, node.item.tenantId, node.item.tenantKindId));
    return data;
  }

  async expandAllChildNodes(nodes: TenantDynamicFlatNode[]) {
    for await (const node of nodes) {
      if (node.item.entityChilds && node.item.entityChilds > 0) {
        const index = this.data.indexOf(node);
        node.isLoading = true;
        const childs = this.database.getChildren(node.item);
        if (childs) {
          const nodes = childs.map(
            (item) => new TenantDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId),
          );
          this.data.splice(index + 1, 0, ...nodes);
          // notify the change
          this.dataChange.next(this.data);
          node.isLoading = false;
          this.isManualLoading = true;
          this.treeControl.expansionModel.select(...[node]);
          this.isManualLoading = false;
          await this.expandAllChildNodes(nodes);
        } else {
          const nodes = await this.manualExpandNode(node);
          node.isLoading = false;
          this.isManualLoading = true;
          this.treeControl.expansionModel.select(...[node]);
          this.isManualLoading = false;
          await this.expandAllChildNodes(nodes);
        }
      }
    }
  }

  async manualExpandNode(node: TenantDynamicFlatNode) {
    const index = this.data.indexOf(node);
    node.isLoading = true;
    const { data } = await firstValueFrom(this.tenantDataService.getTenants(this.request, node.item.tenantId, node.item.tenantKindId));
    let nodes: TenantDynamicFlatNode[] = [];
    if (data) {
      nodes = data.map(
        (item) => new TenantDynamicFlatNode(item, node.level + 1, this.database.isExpandable(item), false, false, node.item.entityId),
      );
      this.database.setChildren(node.item, data);
      this.data.splice(index + 1, 0, ...nodes);
      // notify the change
      this.dataChange.next(this.data);
    } else {
      node.expandable = false;
    }
    node.isLoading = false;
    return nodes;
  }

  /* SELECTION MANAGEMENT */

  isNodeSelected(node: TenantDynamicFlatNode): boolean {
    return this.nodeSelection.isSelected(node.item);
  }

  isNodeChecked(node: TenantDynamicFlatNode): boolean {
    return node.expandable && this.treeControl.isExpanded(node)
      ? this.descendantsAllSelected(node)
      : this.nodeSelection.isSelected(node.item);
  }

  getSelectedNodes() {
    return this.nodeSelection.selected;
  }

  toggleNodeSelection(node: TenantDynamicFlatNode) {
    this.nodeSelection.toggle(node.item);
    const descendants = this.database.getChildren(node.item);
    this.toggleDescendants(this.isNodeSelected(node), descendants);
    this.checkAllParentsSelection(node);
  }

  toggleDescendants(parentNodeSelected: boolean, descendants: TenantTreeNode[]) {
    if (descendants) {
      parentNodeSelected ? this.nodeSelection.select(...descendants) : this.nodeSelection.deselect(...descendants);
      descendants.forEach((childNode) => this.toggleDescendants(parentNodeSelected, this.database.getChildren(childNode)));
    }
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TenantDynamicFlatNode): boolean {
    const descendants = this.database.getChildren(node.item);
    const descAllSelected =
      descendants &&
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.nodeSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TenantDynamicFlatNode): boolean {
    if (node.expandable && this.treeControl.isExpanded(node)) {
      const descendants = this.database.getChildren(node.item);
      const result = descendants ? descendants.some((child) => this.nodeSelection.isSelected(child)) : false;
      const isIndeterminate = result && !this.descendantsAllSelected(node);
      node.isIndeterminate = isIndeterminate;
    }
    return node.isIndeterminate;
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TenantDynamicFlatNode): void {
    let parent: TenantDynamicFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TenantDynamicFlatNode): void {
    const nodeSelected = this.isNodeSelected(node);
    const descendants = this.database.getChildren(node.item);
    const descAllSelected =
      descendants &&
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.nodeSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.nodeSelection.deselect(node.item);
    } else if (!nodeSelected && descAllSelected) {
      this.nodeSelection.select(node.item);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TenantDynamicFlatNode): TenantDynamicFlatNode | null {
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /* Get the parent node of a node */
  getRootParentNodeByItem(item: TenantTreeNode): TenantDynamicFlatNode | null {
    const node = this.data.find((n) => n.item.entityId === item.entityId && n.item.entityKind === item.entityKind);
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level === 0) {
        return currentNode;
      }
    }
    return null;
  }
}

@Injectable()
export class TenantDynamicDatabase {
  dataMap = new Map<TenantTreeNode, TenantTreeNode[]>([]);

  initialData(rootLevelNodes: TenantTreeNode[]): TenantDynamicFlatNode[] {
    const initialNodes: TenantDynamicFlatNode[] = [];
    rootLevelNodes.forEach((node) => {
      initialNodes.push(new TenantDynamicFlatNode(node, 0, this.isExpandable(node), false));
    });

    return initialNodes;
  }

  setChildren(parentNode: TenantTreeNode, children: TenantTreeNode[]) {
    this.dataMap.set(parentNode, children);
  }

  getChildren(node: TenantTreeNode): TenantTreeNode[] | undefined {
    return this.dataMap.get(node);
  }

  removeAllChildren(parentNode: TenantTreeNode) {
    this.dataMap.delete(parentNode);
  }

  isExpandable(node: TenantTreeNode): boolean {
    return node.entityChilds > 0;
  }
}

export class TenantParams {
  //public static FILTER_KIND_IDS = 'filterKindIds';
  //public static FILTER_ADDRESS_IDS = 'filterAddressIds';
}

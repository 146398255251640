import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { DatePeriod } from 'src/app/models/core/date-period';
import { Entity, EntitySelection } from 'src/app/models/entity';
import { TreeGridBaseComponent } from 'src/app/shared/base-components/tree-grid-base-component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MultiselectionTreeGridBaseComponent extends TreeGridBaseComponent {
  @Input() selectedDataSubj: Observable<EntitySelection[]>;
  @Input() addModeSelectAllEnabled = false;
  @Input() filterPeriod: DatePeriod;
  @Input() isReportFilter = false;
  @Input() staticFilterKinds: string[];
  @Input() staticFilterIds: number[][];
  @Input() notSelectableRowConditionField: string;
  @Input() notSelectableRowConditionValue: boolean;
  @Input() enableExtraQuerySelection: boolean;
  @Input() extraQuerySelectionButtonKey: string;
  @Output() entitiesSelectedEvEm = new EventEmitter<Entity[]>();
  @Output() entitiesDeselectedEvEm = new EventEmitter<Entity[]>();
  @Output() clearSelectionEvEm = new EventEmitter<void>();
  @Output() extraQuerySelectionEvEm = new EventEmitter<void>();
  @Output() isLoadingSelectionEvEm = new EventEmitter<boolean>();
  @Output() isLoadingEvEm = new EventEmitter<boolean>();

  // Edit Selection Grid
  isLoadingSelection = false;
  selectionDataSourceTable: MatTableDataSource<EntitySelection>;
  selectionDisplayedColumnsTable = ['entity_name', 'entity_remove'];
  dataSelection: EntitySelection[] = [];

  gbOnInit() {
    if (this.filterPeriod) {
      if (this.filterPeriod.starts != null && moment(this.filterPeriod.starts).isValid()) {
        this.filtersMapStatic.set(this.isReportFilter ? 'filterPeriodStarts' : 'filterStarts', [
          moment(this.filterPeriod.starts).format('YYYY-MM-DD'),
        ]);
      }
      if (this.filterPeriod.ends != null && moment(this.filterPeriod.ends).isValid()) {
        this.filtersMapStatic.set(this.isReportFilter ? 'filterPeriodEnds' : 'filterEnds', [
          moment(this.filterPeriod.ends).format('YYYY-MM-DD'),
        ]);
      }
    }
    if (this.staticFilterKinds && this.staticFilterKinds.length > 0 && this.staticFilterIds && this.staticFilterIds.length > 0) {
      this.staticFilterKinds.forEach((f, index) => {
        if (f != null) {
          this.filtersMapStatic.set(f, this.staticFilterIds[index]);
        }
      });
    }
    this.subscribe(this.selectedDataSubj, (data) => {
      if (data) {
        // SELECTION GRID
        this.selectionDataSourceTable = new MatTableDataSource();
        this.selectionDataSourceTable.data = data;
        this.selectionDataSourceTable.filterPredicate = (data) => {
          return data ? !data.deleted : false;
        };
        this.selectionDataSourceTable.filter = 'true';
        // GRID
        this.dataSelection = data;
        this.initSelection();
      }
    });
  }

  isRowSelected(row: Entity) {
    if (this.dataSelection.length === 0) {
      return false;
    }
    if (this.dataSelection.some((e) => !e.deleted && e.entityId === -1)) {
      return true;
    }
    return this.dataSelection.some((e) => !e.deleted && e.entityId === row.entityId);
  }

  initSelection() {
    if (this.dataSourceTable && this.dataSourceTable.data) {
      this.selection.clear();
      this.dataSourceTable.data.forEach((row) => {
        if (this.isRowSelected(row)) {
          this.selection.toggle(row);
        }
      });
    }
  }

  masterToggle() {
    this.isAllSelected() ? this.removeAllFromSelection() : this.addAllToSelection();
  }

  dropEntity(event: CdkDragDrop<Entity[]>) {
    const droppedEntity: EntitySelection = { ...(event.item.data as Entity), deleted: false };
    if (!this.selectionDataSourceTable.data.some((entity) => droppedEntity && entity.entityId === droppedEntity.entityId)) {
      if (droppedEntity.entityId === -1) {
        this.clearSelectionEvEm.emit();
      }
      this.entitiesSelectedEvEm.emit([droppedEntity]);
    }
  }

  removeEntityFromSelection(row: EntitySelection) {
    this.entitiesDeselectedEvEm.emit([row]);
  }

  addAllToSelection() {
    const rowsToBeSelected = this.dataSourceTable.data.filter((row) => !this.isRowSelected(row));
    rowsToBeSelected.forEach((row) => this.selection.select(row));
    this.entitiesSelectedEvEm.emit(rowsToBeSelected);
  }

  removeAllFromSelection() {
    const rowsToBeDeselected = this.dataSourceTable.data.filter((row) => this.isRowSelected(row) && !this.isReadonly(row));
    rowsToBeDeselected.forEach((row) => this.selection.deselect(row));
    this.entitiesDeselectedEvEm.emit(rowsToBeDeselected);
  }

  isReadonly(row: Entity) {
    if (this.notSelectableRowConditionField != null && this.notSelectableRowConditionValue != null) {
      return this.isNotSelectable(row);
    }
    return this.dataSelection.find((ds) => ds.entityId === row.entityId && ds.isReadonly) != null;
  }

  isNotSelectable(row: Entity) {
    if (this.notSelectableRowConditionField != null && this.notSelectableRowConditionValue != null) {
      return row[this.notSelectableRowConditionField] === this.notSelectableRowConditionValue;
    }
  }

  onCheckboxClicked(row: Entity) {
    this.selection.toggle(row);
    if (this.selection.isSelected(row)) {
      this.entitiesSelectedEvEm.emit([row]);
    } else {
      this.entitiesDeselectedEvEm.emit([row]);
    }
  }

  addToSelection(row: Entity) {
    this.entitiesSelectedEvEm.emit([row]);
  }

  removeFromSelection(row: Entity) {
    this.entitiesDeselectedEvEm.emit([row]);
  }
}

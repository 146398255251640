import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { Costcentre } from '../models/costcentre';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { PaginatedRequest } from '../models/paginated-request';
import { TenantInvoice, TenantInvoiceRequest, TenantPeriod, TenantPeriodRequest, TenantTreeNode } from '../models/tenant';
import { TenantLedger, TenantLedgerCharging, TenantLedgerParticulars, TenantLedgerParticularsRequest } from '../models/tenant-ledger';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class TenantDataService {
  getTenants(request: PaginatedRequest, tenantId?: number, tenantkindId?: number): Observable<BaseResponse<TenantTreeNode[]>> {
    const m = this.getTenants.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = tenantId != null ? params.append('tenantId', tenantId.toString()) : params;
    params = tenantkindId != null ? params.append('tenantkindId', tenantkindId.toString()) : params;
    const retVal = this.http.get<BaseResponse<TenantTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getTenantsSpreadsheet(request: PaginatedRequest) {
    const m = this.getTenantsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        }),
      );
  }

  getTenantLedgerParticularsDefault(tenantId: number): Observable<BaseResponse<TenantLedgerParticulars>> {
    const m = this.getTenantLedgerParticularsDefault.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_LEDGER_PARTICULAR_DEFAULTS);
    LogService.info(this, m, LogService.GET + path, null);
    const params = tenantId != null ? new HttpParams().set('tenantId', tenantId.toString()) : null;
    const retVal = this.http.get<BaseResponse<TenantLedgerParticulars>>(path, { params });
    return retVal;
  }

  createTenantLedgerParticulars(request: TenantLedgerParticularsRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createTenantLedgerParticulars.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_LEDGER_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);
    return retVal;
  }

  getTenantLedgerParticulars(policyId: number): Observable<BaseResponse<TenantLedgerParticulars>> {
    const m = this.getTenantLedgerParticulars.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_LEDGER_PARTICULARS_BYID(policyId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<TenantLedgerParticulars>>(path);
    return retVal;
  }

  putTenantLedgerParticulars(policyId: number, request: TenantLedgerParticularsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putTenantLedgerParticulars.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_LEDGER_PARTICULARS_BYID(policyId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  getTenantLedgers(request: PaginatedRequest): Observable<BaseResponse<TenantLedger[]>> {
    const m = this.getTenantLedgers.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_LEDGERS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<TenantLedger[]>>(path, {
      params,
    });

    return retVal;
  }

  getTenantLedgerChargings(tenantLedgerId: number, request: PaginatedRequest): Observable<BaseResponse<TenantLedgerCharging[]>> {
    const m = this.getTenantLedgerChargings.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_LEDGERS_CHARGINGS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = params.append('filterTenantledgerIds', tenantLedgerId);
    const retVal = this.http.get<BaseResponse<TenantLedgerCharging[]>>(path, {
      params,
    });

    return retVal;
  }

  getTenantPeriods(tenantLedgerId: number, request: PaginatedRequest): Observable<BaseResponse<TenantPeriod[]>> {
    const m = this.getTenantPeriods.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_PERIODS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = params.append('filterTenantledgerIds', tenantLedgerId);
    const retVal = this.http.get<BaseResponse<TenantPeriod[]>>(path, {
      params,
    });

    return retVal;
  }

  getTenantInvoices(request: PaginatedRequest): Observable<BaseResponse<TenantInvoice[]>> {
    const m = this.getTenantInvoices.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_INVOICES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<TenantInvoice[]>>(path, {
      params,
    });

    return retVal;
  }

  getTenantInvoicesSpreadsheet(request: PaginatedRequest) {
    const m = this.getTenantInvoicesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_INVOICES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        }),
      );
  }

  startGeneratingTenantInvoicesSpreadsheet(request: PaginatedRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingTenantInvoicesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_INVOICES_SPREADSHEET);
    LogService.info(this, m, LogService.POST + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, null, { params });

    return retVal;
  }

  startGeneratingTenantInvoicesCsv(request: PaginatedRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.startGeneratingTenantInvoicesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_INVOICES_CSV);
    LogService.info(this, m, LogService.POST + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, null, { params });

    return retVal;
  }

  // downloadTenantInvoiceReport(tenantinvoiceId)

  downloadTenantInvoice(tenantinvoiceId: number): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.downloadTenantInvoice.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_INVOICES_DOWNLOAD(tenantinvoiceId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, null);

    return retVal;
  }

  previewTenantInvoice(tenantinvoiceId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.previewTenantInvoice.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_INVOICES_PREVIEW(tenantinvoiceId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  unlockTenantInvoice(tenantinvoiceId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.unlockTenantInvoice.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_INVOICES_UNLOCK(tenantinvoiceId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  sendTenantInvoice(tenantinvoiceId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.sendTenantInvoice.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_INVOICES_SEND(tenantinvoiceId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  sendAllTenantPeriodInvoice(tenantperiodId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.sendAllTenantPeriodInvoice.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_PERIODS_INVOICES_SENDALL(tenantperiodId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  createTenantInvoice(request: TenantInvoiceRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.downloadTenantInvoice.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_INVOICES_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  createTenantPeriod(request: TenantPeriodRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.createTenantPeriod.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANTS_PERIODS_PARTICULARS);
    LogService.info(this, m, LogService.POST + path, null);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  getTenantAreaCostcentres(tenantId: number, areaId: number, request: PaginatedRequest): Observable<BaseResponse<Costcentre[]>> {
    const m = this.getTenantLedgerChargings.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANT_AREAS_COSTCENTRES(tenantId, areaId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<Costcentre[]>>(path, {
      params,
    });

    return retVal;
  }

  getTenantAreaCostcentresSpreadsheet(tenantId: number, areaId: number, request: PaginatedRequest) {
    const m = this.getTenantAreaCostcentresSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Tenants.TENANT_AREAS_COSTCENTRES_SPREADSHEET(tenantId, areaId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        }),
      );
  }

  constructor(private http: HttpClient) {}
}

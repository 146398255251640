<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span mat-dialog-title> {{ 'entity_form_translations' | translate }}</span>
</div>
<div mat-dialog-content class="dialog-with-bottom-buttons translations-table">
  <div id="entity-table" class="entity-table">
    <div *ngIf="controls" class="table-wrapper">
      <div class="table-container">
        <table class="table table-stripes table-truncate-text" mat-table [dataSource]="keyvaluesTranslations">
          <ng-container matColumnDef="language">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'entity_details_config_language' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" matTooltip="{{ 'language_' + row.key | translate }}" showIfTruncated>
              {{ 'language_' + row.key | translate }}
            </td>
          </ng-container>
          <ng-container matColumnDef="translation">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'entity_details_config_translation' | translate }}
            </th>
            <td mat-cell *matCellDef="let row; let index = index" [matTooltip]="row.value" showIfTruncated>
              <ng-container *ngIf="data.isViewMode">
                {{ row.value }}
              </ng-container>
              <ng-container *ngIf="!data.isViewMode">
                <editable
                  class="editable"
                  (update)="updateField($event, index, 'translation', row.key)"
                  (resetField)="resetField(index, 'translation', row.key)"
                >
                  <ng-template viewMode>
                    <div
                      *ngIf="isValorized(row.value)"
                      [matTooltip]="row.value"
                      showIfTruncated
                      [ngClass]="getControl(index, 'translation').dirty ? 'fo-dirty-behaviour' : ''"
                    >
                      {{ row.value }}
                    </div>
                    <div
                      *ngIf="!isValorized(row.value)"
                      class="invalid"
                      [ngClass]="getControl(index, 'translation').dirty ? 'fo-dirty-behaviour' : ''"
                    >
                      -
                    </div>
                  </ng-template>
                  <ng-template editMode>
                    <mat-form-field floatLabel="never">
                      <input
                        type="text"
                        matInput
                        [formControl]="getControl(index, 'translation')"
                        focusable
                        editableOnEnter
                        resetOnEsc
                        [ngClass]="getControl(index, 'translation').dirty ? 'fo-dirty-behaviour' : ''"
                      />
                    </mat-form-field>
                  </ng-template>
                </editable>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="particulars-form no-background dialog-bottom-buttons">
    <div class="particulars-row no-label">
      <div class="particulars-col"></div>
      <div class="particulars-col"></div>
      <div class="particulars-col float-right">
        <span class="control-value button float-right">
          <ng-container *ngIf="data.isViewMode">
            <button mat-flat-button color="primary" matDialogClose>
              <span>{{ 'label_close' | translate }}</span>
            </button>
          </ng-container>
          <ng-container *ngIf="!data.isViewMode">
            <button mat-stroked-button color="primary" [mat-dialog-close]="null" style="margin-right: 0.5rem">
              <span>{{ 'label_cancel' | translate }}</span>
            </button>
            <button mat-flat-button color="primary" [mat-dialog-close]="keyvaluesTranslations" [disabled]="!isDirtyTableTranslations()">
              <span>{{ 'label_ok' | translate }}</span>
            </button>
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</div>

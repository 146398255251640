<div [ngClass]="isMenuDisabled ? 'sidemenu-disabled' : null"></div>
<div
  class="sidemenu-header"
  [ngStyle]="{
    'background-color': navbarSettings && navbarSettings.headerImageBackgroundColor ? navbarSettings.headerImageBackgroundColor : ''
  }"
>
  <button *ngIf="isAuthenticated || isTenantAdminAuthenticated" type="button" (click)="toggleSideMenu()" class="menu-toggle">
    <i class="fas fa-bars" [ngClass]="isMenuHeaderColorLight ? 'primary-color' : ''"></i>
  </button>
  <img
    *ngIf="sideMenuService.sidemenuExpanded && navbarSettings && navbarSettings.headerImage"
    class="logo"
    [src]="transform(navbarSettings.headerImage)"
    alt="PAGE"
  />
</div>
<div class="sidemenu-container">
  <!-- MENU -->
  <ng-container *ngFor="let sidemenu of sidemenuItems">
    <!-- Module without items -->
    <!--  && !hasChildren(sidemenu) -->
    <a
      [ngClass]="[
        sidemenu.sidemenuAdmin ? 'simple-menu-item admin-menu-item' : 'simple-menu-item',
        sidemenu.sidemenuLink == null ? 'spacer' : '',
        isMenuDisabled ? 'disabled' : ''
      ]"
      [routerLink]="sidemenu.sidemenuLink == null || isMenuDisabled ? null : sidemenu.sidemenuLink"
      [routerLinkActive]="sidemenu.sidemenuLink == null || isMenuDisabled ? '' : 'active'"
      (click)="resetServerErrorFlag()"
      (middleclick)="sidemenu.sidemenuLink == null || isMenuDisabled ? null : openNewTab(sidemenu.sidemenuLink)"
    >
      <div class="icon-column">
        <i *ngIf="sidemenu.sidemenuIcon" class="menu-item-icon fas" [ngClass]="sidemenu.sidemenuIcon"></i>
      </div>
      <div class="title-column" *ngIf="sideMenuService.sidemenuExpanded">
        <span class="menu-item-title">{{ sidemenu.sidemenuName | translate }}</span>
      </div>
    </a>
    <!-- Module with items -->
    <!-- <mat-expansion-panel
      [ngClass]="[
        sidemenu.sidemenuAdmin ? 'expandable-menu-item admin-menu-item' : 'expandable-menu-item',
        sideMenuService.sidemenuExpanded ? 'expanded' : ''
      ]"
      *ngIf="sidemenu.sidemenuEnabled && hasChildren(sidemenu)"
      [expanded]="sidemenu.sidemenuExpanded"
      [hideToggle]="true"
    > -->
    <!--LAYER 1-->
    <!-- <mat-expansion-panel-header
        (click)="sidemenu.sidemenuExpanded ? null : (sideMenuService.sidemenuExpanded = true); sidemenu.sidemenuExpanded = !sidemenu.sidemenuExpanded"
      >
        <div class="icon-column">
          <i *ngIf="sidemenu.sidemenuIcon" class="menu-item-icon fas" [ngClass]="sidemenu.sidemenuIcon"></i>
        </div>
        <div class="item-header-content title-column" *ngIf="sideMenuService.sidemenuExpanded">
          <mat-panel-title class="expandable-item-title">
            <span>{{ sidemenu.sidemenuTextname | translate }}</span>
          </mat-panel-title>
        </div>
        <mat-icon [ngClass]="['expansion-indicator', sidemenu.sidemenuExpanded ? 'expanded' : '']"> expand_more </mat-icon>
      </mat-expansion-panel-header> -->
    <!-- LAYER 2 -->
    <!-- <ng-container *ngFor="let item of sidemenu.children">
        <ng-container *ngIf="!hasChildren(item)">
          <a *ngIf="item.sidemenuEnabled" class="expandable-sub-item" [routerLink]="item.sidemenuLink" routerLinkActive="active">
            <span class="menu-item-title" *ngIf="sideMenuService.sidemenuExpanded">{{ item.sidemenuTextname | translate }}</span>
          </a>
        </ng-container> -->
    <!-- Module with items and subitems-->
    <!-- <ng-container *ngIf="item.sidemenuEnabled && hasChildren(item)">
          <mat-expansion-panel
            [ngClass]="sidemenu.sidemenuAdmin ? 'expandable-sub-menu-item admin-menu-item' : 'expandable-sub-menu-item'"
            [expanded]="item.sidemenuExpanded"
            [hideToggle]="true"
          >
            <mat-expansion-panel-header (click)="item.expanded = !item.sidemenuExpanded">
              <div class="item-header-content">
                <mat-panel-title class="expandable-item-title">
                  <span>{{ item.sidemenuTextname | translate }}</span>
                </mat-panel-title>
              </div>
              <mat-icon [ngClass]="['expansion-indicator', item.sidemenuExpanded ? 'expanded' : '']"> expand_more </mat-icon>
            </mat-expansion-panel-header>
            LAYER 3
            <ng-container *ngFor="let subitem of item.children">
              <a *ngIf="subitem.sidemenuEnabled" class="expandable-sub-item" [routerLink]="subitem.sidemenuLink" routerLinkActive="active">
                <span class="menu-item-title" *ngIf="sideMenuService.sidemenuExpanded">
                  {{ subitem.sidemenuTextname | translate }}
                </span>
              </a>
            </ng-container>
          </mat-expansion-panel>
        </ng-container> -->
    <!-- </ng-container>
    </mat-expansion-panel> -->
  </ng-container>
  <!-- Feedback menu items -->
  <a
    *ngIf="feedbackEmail"
    class="simple-menu-item feedback-menu-item"
    [ngClass]="[sideMenuService.sidemenuExpanded ? 'expanded' : '']"
    href="mailto:{{ feedbackEmail }}?subject=Feedback FinOffice"
  >
    <div class="icon-column">
      <i class="menu-item-icon fas fa-comments"></i>
    </div>
    <div class="title-column" *ngIf="sideMenuService.sidemenuExpanded">
      <span class="menu-item-title">{{ 'sidemenu_feedback' | translate }}</span>
    </div>
  </a>
  <mat-divider *ngIf="sideMenuService.sidemenuExpanded"></mat-divider>
  <div *ngIf="sideMenuService.sidemenuExpanded && quoteText && quoteAuthor" class="sidemenu-quote-container">
    <span class="quote">"{{ quoteText }}"</span>
    <span class="author">{{ quoteAuthor }}</span>
  </div>
</div>
<a (click)="openExternalLink('http://finoffice.com/')" class="footer">
  <span class="menu-item-title">{{ sideMenuService.sidemenuExpanded ? environtment.poweredByFull : environtment.poweredByReduced }}</span>
</a>

<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span mat-dialog-title>
    {{ 'label_release_update_news' | translate }}
  </span>
</div>
<div mat-dialog-content class="dialog-with-bottom-buttons news-content">
  <div class="news-table entity-table">
    <div class="table-wrapper">
      <div class="table-container">
        <table class="table table-truncate-text no-header-table" mat-table [dataSource]="dataSourceTable">
          <ng-container matColumnDef="news">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <div class="news-container">
                <span class="category" [ngClass]="row.newsCategoryId === 10 ? 'feature' : 'bugfix'">
                  {{ row.newsCategory }}
                </span>
                <span class="topic"> {{ getDate(row.newsReleasedate) }} - {{ row.newsTopic }} </span>
                <span class="content" [innerHTML]="row.newsContent"> </span>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="particulars-form no-background dialog-bottom-buttons">
    <div class="particulars-row no-label">
      <div class="particulars-col"></div>
      <div class="particulars-col"></div>
      <div class="particulars-col float-right">
        <span class="control-value button float-right">
          <button [mat-dialog-close]="null" mat-flat-button color="primary">
            {{ 'label_close' | translate }}
          </button>
        </span>
      </div>
    </div>
  </div>
</div>

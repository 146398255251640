import { Flags } from '../configs/flags';

export class BaseResponse<T> {
  data: T;
  status: number;
  timestamp: string;
  entityKind: string;
  flags: string[];
  filters: string[];
  error?: string;
}

export function hasCreateFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.CREATE_FLAG);
}

export function hasOrderFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.ORDER_FLAG);
}

export function hasDocumentFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.DOCUMENT_FLAG);
}

export function hasNotesFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.NOTES_FLAG);
}

export function hasAutoexpandFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.AUTOEXPAND_FLAG);
}

export function hasExcelFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.EXCEL_FLAG);
}

export function hasPdfFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.PDF_FLAG);
}

export function hasCsvFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.CSV_FLAG);
}

export function hasOutstandingsFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.OUTSTANDINGS_FLAG);
}

export function hasMultipleCostcentresFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.MULTIPLE_COSTCENTRES_FLAG);
}

export function hasSingleAttachmentsFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.SINGLE_ATTACHMENTS_FLAG);
}

export function hasMultipleAttachmentsFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.MULTIPLE_ATTACHMENTS_FLAG);
}

export function has3diProfilerFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.THREEDI_PROFILER_FLAG);
}

export function hasCollaborationModuleFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.COLLABORATION_MODULE_FLAG);
}

export function hasInvoiceItemEditFlag<T>(response: BaseResponse<T>): boolean {
  return response.flags && response.flags.includes(Flags.INVOICE_ITEM_EDIT_FLAG);
}

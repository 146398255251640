<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span mat-dialog-title>
    {{ 'modal_dialog_please_select' | translate }}
    {{ Entities.FILE | translate }}
  </span>
</div>
<div mat-dialog-content class="dialog-with-bottom-buttons">
  <div id="file-modal" class="entity-table">
    <mat-tab-group class="entity-tab-group" id="file-tab-group">
      <!-- GENERIC TAB -->
      <!-- <mat-tab label="{{ 'files_generic_tree' | translate }}">
      <ng-template matTabContent>
        <app-file-tab [tabType]="FileTabType.generic" (fileSelected)="fileSelected($event)" [isModal]="true"></app-file-tab>
      </ng-template>
    </mat-tab> -->
      <!-- COMPANY TAB -->
      <mat-tab label="{{ 'files_company_tree' | translate }}">
        <ng-template matTabContent>
          <app-file-tab [tabType]="FileTabType.company" (fileSelected)="fileSelected($event)" [isModal]="true"></app-file-tab>
        </ng-template>
      </mat-tab>
      <!-- USER TAB -->
      <mat-tab label="{{ 'files_user_tree' | translate }}">
        <ng-template matTabContent>
          <app-file-tab [tabType]="FileTabType.user" (fileSelected)="fileSelected($event)" [isModal]="true"></app-file-tab>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="particulars-form no-background dialog-bottom-buttons">
    <div class="particulars-row no-label">
      <div class="particulars-col"></div>
      <div class="particulars-col"></div>
      <div class="particulars-col float-right">
        <span class="control-value button float-right">
          <button mat-stroked-button color="primary" (click)="onCancel()" style="margin-right: 0.5rem">
            <span>{{ 'label_cancel' | translate }}</span>
          </button>
          <button mat-flat-button color="primary" [mat-dialog-close]="selectedFile" [disabled]="!selectedFile">
            <span>{{ 'label_select' | translate }}</span>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Entities } from 'src/app/configs/entities';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { FormatComponent } from '../../base-components/format-component';
import { FileTabType } from '../file-tab/file-tab-type';

export interface DialogData {
  labelName: string;
  kind: string;
}

@Component({
  selector: 'app-file-modal',
  templateUrl: './file-modal.component.html',
  styleUrls: ['./file-modal.component.scss'],
})
export class FileModalComponent extends FormatComponent implements OnInit {
  selectedFile: FileGridNode;

  constructor(public dialogRef: MatDialogRef<FileModalComponent>) {
    super();
  }

  ngOnInit() {}

  get FileTabType() {
    return FileTabType;
  }

  get Entities() {
    return Entities;
  }

  fileSelected(row: FileGridNode) {
    this.selectedFile = row;
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiPath } from 'src/app/configs/api-paths';
import {
  CustomConfigurableModalComponent,
  CustomConfigurableModalData,
} from 'src/app/core/modal/custom-configurable-modal/custom-configurable-modal.component';
import { ForeignEntity } from 'src/app/models/foreign-entity';
import { GenericFormFieldRequiredType } from 'src/app/models/forms/form-field';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { CoreDataService } from 'src/app/services/core-data.service';
import {
  GenericEntityWizardModalDialogComponent,
  GenericEntityWizardModalDialogData,
} from '../../../generic-entity-wizard-modal-dialog/generic-entity-wizard-modal-dialog.component';
import { BaseFormFieldComponent } from '../base-form-field/base-form-field-component';

@Component({
  selector: 'app-form-field-search',
  templateUrl: './form-field-search.component.html',
  styleUrls: ['./form-field-search.component.scss'],
})
export class FormFieldSearchComponent extends BaseFormFieldComponent implements OnInit {
  constructor(private dialog: MatDialog, protected coreDataService: CoreDataService) {
    super();
  }

  foreignEntityOptions: ForeignEntity[] = [];
  wizardName: string;

  tempUserInputString: string;

  ngOnInit() {
    if (this.saveSubj) {
      this.subscribe(this.saveSubj, () => (this.isSavePressed = true));
    }
  }

  checkSelectedOption() {
    this.tempUserInputString = this.formFieldData.formfieldEntityText;
    if (!this.foreignEntityOptions || !this.foreignEntityOptions.find((fe) => fe.entityName === this.formFieldData.formfieldEntityText)) {
      this.formFieldData.formfieldEntityText = null;
      this.formFieldData.formfieldEntityValue = null;
    }
  }

  openDialog() {
    this.dialog
      .open(CustomConfigurableModalComponent, {
        panelClass: 'custom-configurable-modal',
        width: '60vw',
        height: '80vh',
        data: {
          labelName: this.formFieldData.formfieldTextname,
          basePath: ApiPath.Forms.FORMS_SEARCH_BY_FORMFIELDID(this.formFieldData.formfieldId),
        } as CustomConfigurableModalData,
      })
      .afterClosed()
      .subscribe((entities) => {
        if (entities && entities.length > 0) {
          this.foreignEntityOptions = [entities[0] as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, entities[0]);
        }
      });
  }

  loadDefaultOptions() {
    document.body.classList.add('waiting-mouse-cursor');
    const request = { ...new PaginatedRequest(), pageSize: 10, orderBy: 'entityName' };
    this.subscribe(
      this.coreDataService.getForeignEntity(ApiPath.Forms.FORMS_SEARCH_BY_FORMFIELDID(this.formFieldData.formfieldId), request),
      ({ data }) => {
        if (
          this.formFieldData.formfieldEntityText == null ||
          this.formFieldData.formfieldEntityText === '' ||
          this.formFieldData.formfieldEntityValue == null
        ) {
          this.foreignEntityOptions = data;
        } else {
          this.foreignEntityOptions = [
            {
              ...new ForeignEntity(),
              entityId: this.formFieldData.formfieldEntityValue,
              entityName: this.formFieldData.formfieldEntityText,
            },
            ...data.filter((d) => d.entityId !== this.formFieldData.formfieldEntityValue),
          ];
        }
        document.body.classList.remove('waiting-mouse-cursor');
      }
    );
  }

  onForeignOptionSelectionChanged(entityName: string, dialogEntity?: ForeignEntity) {
    const entity = dialogEntity ? dialogEntity : this.foreignEntityOptions.find((fe) => fe.entityName === entityName);
    this.formFieldData.formfieldEntityText = entity ? (entity as ForeignEntity).entityName : null;
    this.formFieldData.formfieldEntityValue = entity ? (entity as ForeignEntity).entityId : null;
    this.changeValueEvEm.emit();
  }

  clear() {
    this.formFieldData.formfieldEntityText = null;
    this.formFieldData.formfieldEntityValue = null;
    this.changeValueEvEm.emit();
  }

  createForeignEntity() {
    this.wizardName = this.formFieldData.formfieldEntityKind;
    const openedDialogs = this.dialog.openDialogs.length;
    const dialogRef = this.dialog.open(GenericEntityWizardModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-entity-wizard-modal-dialog',
      disableClose: true,

      data: {
        wizardName: this.wizardName,
        inputValue: this.tempUserInputString,
        isDocumentsTabEnabled: this.isDocumentEnabled,
      } as GenericEntityWizardModalDialogData,
    });
    if (openedDialogs > 0) {
      const elemRect = this.dialog.openDialogs[openedDialogs - 1]._containerInstance['_elementRef'].nativeElement.getBoundingClientRect();
      const top = elemRect.top + 10;
      const left = elemRect.left + 10;
      dialogRef.updatePosition({ top: `${top}px`, left: `${left}px` });
    }
    this.subscribe(dialogRef.afterClosed(), (entity) => {
      if (entity) {
        this.formFieldData.formfieldEntityText = entity ? (entity as ForeignEntity).entityName : null;
        this.formFieldData.formfieldEntityValue = entity ? (entity as ForeignEntity).entityId : null;
        // this.autoUpdateEvEm.emit();
        this.changeValueEvEm.emit();
      }
    });
  }

  get ApiPath() {
    return ApiPath;
  }
  get GenericFormFieldRequiredType() {
    return GenericFormFieldRequiredType;
  }
}

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Observable } from 'rxjs';
import { Entities } from 'src/app/configs/entities';
import { TypesKind } from 'src/app/configs/types-kind';
import { DatePeriod } from 'src/app/models/core/date-period';
import { Entity, EntitySelection } from 'src/app/models/entity';
import { FormatComponent } from '../../base-components/format-component';

@Component({
  selector: 'app-generic-multigrid-multiselection',
  templateUrl: './generic-multigrid-multiselection.component.html',
  styleUrls: ['./generic-multigrid-multiselection.component.scss'],
})
export class GenericMultigridMultiselectionComponent extends FormatComponent implements OnInit, OnChanges {
  @Input() selectedDataSubj: Observable<EntitySelection[]>;
  @Input() isStaticData = false;
  @Input() staticDataOptions: KeyValue<string, string>[] = [];
  @Input() apiPath: string;
  @Input() entityKind: string;
  @Input() relationEntityKind: string;
  @Input() typesKindId: number;
  @Input() filterPeriod: DatePeriod;
  @Input() isReportFilter = false;
  @Input() addModeSelectAllEnabled = false;
  @Input() staticFilterKinds: string[];
  @Input() staticFilterIds: number[][];
  @Input() filterColumn1: string;
  @Input() filterColumn1Values: number[];
  @Input() notSelectableRowConditionField: string;
  @Input() notSelectableRowConditionValue: boolean;
  @Input() enableCreation = false;
  @Input() enableExtraQuerySelection = false;
  @Input() extraQuerySelectionButtonKey = _('label_select_from');
  @Input() createFieldTextnames: string[];
  @Input() createFieldValues: any[];
  @Output() addToSelectionEvEm = new EventEmitter<EntitySelection[]>();
  @Output() removeFromSelectionEvEm = new EventEmitter<EntitySelection[]>();
  @Output() clearSelectionEvEm = new EventEmitter<void>();
  @Output() extraQuerySelectionEvEm = new EventEmitter<void>();
  @Output() isLoadingSelectionEvEm = new EventEmitter<boolean>();
  @Output() isLoadingEvEm = new EventEmitter<boolean>();

  // Edit Selection Grid
  selectionDataSourceTable: MatTableDataSource<EntitySelection>;
  selectionDisplayedColumnsTable = ['entity_name', 'entity_remove'];
  isLoadingKind = false;
  isLoadingSelection = false;
  isLoading = false;

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.selectionDataSourceTable = new MatTableDataSource();
    this.subscribe(this.selectedDataSubj, (data) => {
      // this.isLoading = true;
      // this.cdRef.detectChanges();
      if (data) {
        this.selectionDataSourceTable.data = data;
        this.selectionDataSourceTable.filterPredicate = (data) => {
          return data ? !data.deleted : false;
        };
        this.selectionDataSourceTable.filter = 'true';
      }
      // this.isLoading = false;
      // this.cdRef.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectionDataSourceTable) {
      if (changes.entityKind && changes.entityKind.currentValue !== changes.entityKind.previousValue) {
        this.isLoadingKind = true;
        this.cdRef.detectChanges();
        this.entityKind = changes.entityKind.currentValue;
        this.isLoadingKind = false;
        this.cdRef.detectChanges();
      }

      if (changes.typesKindId && changes.typesKindId.currentValue !== changes.typesKindId.previousValue) {
        this.isLoadingKind = true;
        this.cdRef.detectChanges();
        this.typesKindId = changes.typesKindId.currentValue;
        this.isLoadingKind = false;
        this.cdRef.detectChanges();
      }
    }
  }

  isLoadingSelectionChanged($event: boolean) {
    this.isLoadingSelection = $event;
    this.isLoadingSelectionEvEm.emit(this.isLoadingSelection);
  }

  isLoadingChanged($event: boolean) {
    this.isLoading = $event;
    this.isLoadingEvEm.emit(this.isLoading);
  }

  removeEntityFromSelection(row: EntitySelection) {
    this.removeFromSelectionEvEm.emit([row]);
  }

  dropEntity(event: CdkDragDrop<Entity[]>) {
    const droppedEntity: EntitySelection = { ...(event.item.data as Entity), deleted: false };
    if (!this.selectionDataSourceTable.data.some((entity) => droppedEntity && entity.entityId === droppedEntity.entityId)) {
      if (droppedEntity.entityId === -1) {
        this.clearSelectionEvEm.emit();
      }
      this.addToSelectionEvEm.emit([droppedEntity]);
    }
  }

  entitiesSelection(entities: Entity[]) {
    const selectedEntities: EntitySelection[] = [];
    for (let index = 0; index < entities.length; index++) {
      const entity = entities[index];
      const droppedEntity: EntitySelection = { ...entity, deleted: false };
      if (!this.selectionDataSourceTable.data.some((e) => droppedEntity && e.entityId === droppedEntity.entityId && !e.deleted)) {
        if (droppedEntity.entityId === -1) {
          this.clearSelectionEvEm.emit();
          this.addToSelectionEvEm.emit([droppedEntity]);
          return;
        } else {
          selectedEntities.push(droppedEntity);
        }
      }
    }
    this.addToSelectionEvEm.emit(selectedEntities);
  }

  entitiesDeselection(entities: Entity[]) {
    this.removeFromSelectionEvEm.emit(
      entities.map((entity) => {
        return { ...entity, deleted: false };
      })
    );
  }

  get Entities() {
    return Entities;
  }

  get TypesKind() {
    return TypesKind;
  }
}

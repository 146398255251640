<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span mat-dialog-title>
    {{ 'modal_dialog_please_select' | translate }}
    {{ data.labelName | translate }}
  </span>
</div>
<div mat-dialog-content class="dialog-with-bottom-buttons">
  <!-- DIALOG CONTENT -->
  <div id="entity-table" class="entity-table">
    <div class="table-wrapper">
      <div class="table-search-paginate hidden-filters">
        <div class="table-search-paginate-left-wrapper">
          <mat-form-field appearance="outline" class="reset-outline-hint filter-field search"
            ><button *ngIf="filter" mat-icon-button matSuffix class="small-icon" (click)="clearSearchFilter(); $event.stopPropagation()">
              <mat-icon>clear</mat-icon>
            </button>
            <mat-autocomplete
              (opened)="matAutocompletPanelOpened()"
              (closed)="matAutocompletPanelClosed()"
              #autoLastStringOption="matAutocomplete"
              (optionSelected)="applyFilterTable($event.option.value)"
            >
              <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
                {{ 'label_recent_searches' | translate }}
              </mat-option>
              <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
                {{ opt }}
              </mat-option>
            </mat-autocomplete>
            <input
              #searchInputField
              #searchInputAutocompleteTrigger="matAutocompleteTrigger"
              (input)="searchInputAutocompleteTrigger.closePanel()"
              matInput
              [(ngModel)]="filter"
              [matAutocomplete]="autoLastStringOption"
              (debounceFilterInput)="applyFilterTable($event)"
              [debounceTime]="600"
              maxlength="1500"
              placeholder="{{ 'table_filter_placeholder' | translate }}"
            />
          </mat-form-field>
          <ng-container *ngIf="data?.enableFilterRules && searchFilterrules">
            <span style="padding-right: 0.5rem"></span>
            <div class="search-filterrules">
              <mat-button-toggle-group
                class="topics"
                #kindButtonGroup="matButtonToggleGroup"
                [multiple]="true"
                [value]="[selectedFilteruleId]"
                (change)="setFilterRule($event.value)"
              >
                <mat-button-toggle [value]="1">
                  <span>{{ 'label_exact_match' | translate }}</span>
                </mat-button-toggle>
                <mat-button-toggle [value]="2">
                  <span>{{ 'label_begin_with' | translate }}</span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </ng-container>
          <mat-radio-group
            class="grid-tree-radio-group"
            *ngIf="isGridTreeSelectionActive"
            [(value)]="isGridView"
            (change)="onGridTreeSelectionChanged($event)"
          >
            <mat-radio-button [value]="true">{{ 'label_grid_view' | translate }}</mat-radio-button>
            <mat-radio-button [value]="false">{{ 'label_tree_view' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="table-search-paginate-right">
          <ng-container *ngIf="showFilters">
            <button mat-button color="primary" (click)="enableFilter = !enableFilter" *ngIf="data?.gridFiltersConfig">
              {{ enableFilter ? ('label_hide_filters' | translate) : ('label_more_filters' | translate) }}
            </button>
            <button mat-button color="warn" (click)="clearFilters()" *ngIf="isFiltersSetted()">
              {{ 'label_reset_filters' | translate }}
            </button>
          </ng-container>
        </div>
      </div>
      <div class="table-filters" *ngIf="data?.gridFiltersConfig" [ngClass]="enableFilter ? 'visible' : 'hidden'">
        <ng-container *ngFor="let filter of data?.gridFiltersConfig">
          <generic-grid-filter-panel
            *ngIf="!filter.isDateFilter"
            [clearFilter]="clearFilterSubject.asObservable()"
            [filtersMapSubj]="filtersMapSubject.asObservable()"
            [filterName]="filter.filterParams"
            [filterApiPath]="filter.filterApiPath"
            filterTitle="{{ filter.filterTitle | translate }}"
            (filterIdsEvEm)="setFiltersIds($event, filter.filterParams)"
          ></generic-grid-filter-panel>
          <generic-grid-filter-date-panel
            *ngIf="filter.isDateFilter"
            [clearFilter]="clearFilterSubject.asObservable()"
            [filtersMapSubj]="filtersMapSubject.asObservable()"
            [filterName]="filter.filterParams"
            filterTitle="{{ filter.filterTitle | translate }}"
            (filterDatesEvEm)="setFiltersIds($event, filter.filterParams)"
          ></generic-grid-filter-date-panel>
        </ng-container>
      </div>
      <div class="table-container">
        <table
          *ngIf="isGridView"
          class="table table-stripes table-truncate-text"
          mat-table
          [dataSource]="dataSourceTable"
          matSort
          #sortTable="matSort"
          [matSortActive]="gbGetInitialOrderBy()"
          [matSortDirection]="gbGetInitialSort()"
          matSortDisableClear
          [matSortActive]="data.disableSorting ? '' : 'entityName'"
          [matSortDirection]="data.disableSorting ? '' : 'asc'"
        >
          <!-- radio button column -->
          <ng-container matColumnDef="radio">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
              <mat-radio-button
                class="table-radio-button"
                (change)="onEntitySelected(row)"
                disableRipple="true"
                [checked]="selectedEntity && selectedEntity.entityId === row.entityId"
              ></mat-radio-button>
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? onCheckboxClicked(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Configurable columns -->
          <ng-container *ngIf="data?.gridColumnsConfig">
            <ng-container *ngFor="let c of data?.gridColumnsConfig">
              <ng-container matColumnDef="{{ c.entityField }}">
                <ng-container *ngIf="data.disableSorting">
                  <th mat-header-cell *matHeaderCellDef [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }">
                    {{ c.columnTitleKey | translate }}
                  </th>
                </ng-container>
                <ng-container *ngIf="!data.disableSorting">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }">
                    {{ c.columnTitleKey | translate }}
                  </th>
                </ng-container>
                <td
                  mat-cell
                  *matCellDef="let row"
                  [matTooltip]="getTooltipString(c, row)"
                  showIfTruncated
                  [ngSwitch]="c.columnType"
                  [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }"
                  [ngClass]="c.columnType === 'tooltip' ? 'tooltip-cell' : ''"
                >
                  <span *ngSwitchCase="'date'">{{ getDate(row[c.entityField]) }}</span>
                  <span *ngSwitchCase="'timestamp'">{{ getTimestamp(row[c.entityField]) }}</span>
                  <span *ngSwitchCase="'price'">{{
                    row[c.entityField] != null ? getMoney(row[c.entityField]) : row[c.columnPriceSwitchField]
                  }}</span>
                  <span *ngSwitchCase="'factor'">{{ getFactor(row[c.entityField]) }}</span>
                  <span *ngSwitchCase="'percentage'">{{ getPercentageNormalized(row[c.entityField]) }}</span>
                  <span *ngSwitchCase="'boolean'">
                    <ng-container *ngIf="row[c.entityField]">
                      <mat-icon style="height: auto; vertical-align: middle; color: green; font-size: 20px">check</mat-icon>
                    </ng-container>
                  </span>
                  <ng-container *ngSwitchCase="'tooltip'">
                    <mat-icon *ngIf="row[c.entityField]" matTooltip="{{ row[c.entityField] }}" [matTooltipClass]="'fo-mat-tooltip'"
                      >info</mat-icon
                    >
                  </ng-container>

                  <span *ngSwitchDefault>{{ row[c.entityField] }}</span>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr
            mat-row
            (click)="onEntitySelected(row)"
            *matRowDef="let row; columns: displayedColumnsTable"
            [ngClass]="selectedEntity && selectedEntity.entityId === row.entityId ? 'selected-row' : ''"
          ></tr>
        </table>

        <table
          *ngIf="!isGridView"
          class="table table-stripes table-truncate-text"
          mat-table
          [dataSource]="dataSource"
          [ngStyle]="{ visibility: !isLoading ? '' : 'hidden' }"
          matSort
          #sortTable="matSort"
          [matSortActive]="gbGetInitialOrderBy()"
          [matSortDirection]="gbGetInitialSort()"
          matSortDisableClear
          [matSortActive]="data.disableSorting ? '' : 'entityName'"
          [matSortDirection]="data.disableSorting ? '' : 'asc'"
        >
          <!-- Configurable columns -->
          <ng-container *ngIf="data?.gridColumnsConfig">
            <ng-container matColumnDef="{{ data?.gridColumnsConfig[0].entityField }}">
              <ng-container *ngIf="data.disableSorting">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [ngStyle]="{ width: data?.gridColumnsConfig[0].columnWidth != null ? data?.gridColumnsConfig[0].columnWidth : '' }"
                >
                  {{ data?.gridColumnsConfig[0].columnTitleKey | translate }}
                </th>
              </ng-container>
              <ng-container *ngIf="!data.disableSorting">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [ngStyle]="{ width: data?.gridColumnsConfig[0].columnWidth != null ? data?.gridColumnsConfig[0].columnWidth : '' }"
                >
                  {{ data?.gridColumnsConfig[0].columnTitleKey | translate }}
                </th>
              </ng-container>
              <td
                mat-cell
                *matCellDef="let row"
                [ngSwitch]="data?.gridColumnsConfig[0].columnType"
                [ngStyle]="{ width: data?.gridColumnsConfig[0].columnWidth != null ? data?.gridColumnsConfig[0].columnWidth : '' }"
              >
                <div class="toggle-checkbox-name">
                  <div class="icon-toggle-cell" *ngIf="showTreeToggle">
                    <button
                      mat-icon-button
                      [style.visibility]="!row.expandable ? 'hidden' : ''"
                      [style.marginLeft.px]="row.level * 16"
                      (click)="treeControl.toggle(row); $event.stopPropagation()"
                    >
                      <mat-icon class="mat-icon-rtl-mirror expand-icon">
                        {{ treeControl.isExpanded(row) ? 'remove' : 'add' }}
                      </mat-icon>
                    </button>
                  </div>
                  <mat-radio-button
                    class="table-radio-button"
                    (change)="onEntitySelected(row.item)"
                    disableRipple="true"
                    [checked]="selectedEntity && selectedEntity.entityId === row.item.entityId"
                  ></mat-radio-button>
                  <span
                    class="toggle-name"
                    [matTooltip]="getTooltipString(data?.gridColumnsConfig[0], row)"
                    showIfTruncated
                    [ngClass]="row.isLoading ? 'loading' : ''"
                    *ngSwitchCase="'date'"
                    >{{ getDate(row.item[data?.gridColumnsConfig[0].entityField]) }}</span
                  >
                  <span
                    class="toggle-name"
                    [matTooltip]="getTooltipString(data?.gridColumnsConfig[0], row)"
                    showIfTruncated
                    [ngClass]="row.isLoading ? 'loading' : ''"
                    *ngSwitchCase="'timestamp'"
                    >{{ getTimestamp(row.item[data?.gridColumnsConfig[0].entityField]) }}</span
                  >
                  <span
                    class="toggle-name"
                    [matTooltip]="getTooltipString(data?.gridColumnsConfig[0], row)"
                    showIfTruncated
                    [ngClass]="row.isLoading ? 'loading' : ''"
                    *ngSwitchCase="'price'"
                    >{{
                      row.item[data?.gridColumnsConfig[0].entityField] != null
                        ? getMoney(row.item[data?.gridColumnsConfig[0].entityField])
                        : row.item[data?.gridColumnsConfig[0].columnPriceSwitchField]
                    }}</span
                  >
                  <span
                    class="toggle-name"
                    [ngClass]="row.isLoading ? 'loading' : ''"
                    [matTooltip]="getTooltipString(data?.gridColumnsConfig[0], row)"
                    showIfTruncated
                    *ngSwitchCase="'factor'"
                    >{{ getFactor(row.item[data?.gridColumnsConfig[0].entityField]) }}</span
                  >
                  <span
                    class="toggle-name"
                    [matTooltip]="getTooltipString(data?.gridColumnsConfig[0], row)"
                    showIfTruncated
                    [ngClass]="row.isLoading ? 'loading' : ''"
                    *ngSwitchCase="'percentage'"
                    >{{ getPercentageNormalized(row.item[data?.gridColumnsConfig[0].entityField]) }}</span
                  >
                  <span
                    class="toggle-name"
                    [matTooltip]="getTooltipString(data?.gridColumnsConfig[0], row)"
                    showIfTruncated
                    [ngClass]="row.isLoading ? 'loading' : ''"
                    *ngSwitchCase="'boolean'"
                  >
                    <ng-container *ngIf="row.item[data?.gridColumnsConfig[0].entityField]">
                      <mat-icon style="height: auto; vertical-align: middle; color: green; font-size: 20px">check</mat-icon>
                    </ng-container>
                  </span>
                  <span
                    class="toggle-name"
                    [matTooltip]="getTooltipString(data?.gridColumnsConfig[0], row)"
                    showIfTruncated
                    [ngClass]="row.isLoading ? 'loading' : ''"
                    *ngSwitchDefault
                    >{{ row.item[data?.gridColumnsConfig[0].entityField] }}</span
                  >
                  <mat-progress-bar *ngIf="row.isLoading" mode="indeterminate" class="example-tree-progress-bar"> </mat-progress-bar>
                </div>
              </td>
            </ng-container>
            <ng-container *ngFor="let c of data?.gridColumnsConfig.slice(1)">
              <ng-container matColumnDef="{{ c.entityField }}">
                <ng-container *ngIf="data.disableSorting">
                  <th mat-header-cell *matHeaderCellDef [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }">
                    {{ c.columnTitleKey | translate }}
                  </th>
                </ng-container>
                <ng-container *ngIf="!data.disableSorting">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }">
                    {{ c.columnTitleKey | translate }}
                  </th>
                </ng-container>
                <td
                  mat-cell
                  *matCellDef="let row"
                  [matTooltip]="getTooltipString(c, row)"
                  showIfTruncated
                  [ngSwitch]="c.columnType"
                  [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }"
                >
                  <span *ngSwitchCase="'date'">{{ getDate(row.item[c.entityField]) }}</span>
                  <span *ngSwitchCase="'timestamp'">{{ getTimestamp(row.item[c.entityField]) }}</span>
                  <span *ngSwitchCase="'price'">{{
                    row.item[c.entityField] != null ? getMoney(row.item[c.entityField]) : row.item[c.columnPriceSwitchField]
                  }}</span>
                  <span *ngSwitchCase="'factor'">{{ getFactor(row.item[c.entityField]) }}</span>
                  <span *ngSwitchCase="'percentage'">{{ getPercentageNormalized(row.item[c.entityField]) }}</span>
                  <span *ngSwitchCase="'boolean'">
                    <ng-container *ngIf="row.item[c.entityField]">
                      <mat-icon style="height: auto; vertical-align: middle; color: green; font-size: 20px">check</mat-icon>
                    </ng-container>
                  </span>
                  <span *ngSwitchDefault>{{ row.item[c.entityField] }}</span>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTreeTable; sticky: true"></tr>
          <tr
            mat-row
            (click)="onEntitySelected(row.item)"
            *matRowDef="let row; columns: displayedColumnsTreeTable"
            [ngClass]="
              selectedEntity && selectedEntity.entityId === row.item.entityId && selectedEntity.entityKind === row.item.entityKind
                ? 'selected-row'
                : ''
            "
          ></tr>
        </table>
        <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
          <mat-toolbar-row *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-toolbar-row>
          <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
            <span>{{ 'generic_table_error_nodata' | translate }}</span>
          </mat-toolbar-row>
        </mat-toolbar>
        <mat-toolbar class="table-footer-toolbar">
          <mat-toolbar-row>
            <mat-paginator
              [disabled]="isLoading"
              #paginatorTable
              [pageSizeOptions]="pageSizeList"
              [length]="pageTotalElements"
              class="reset-paginator"
            ></mat-paginator>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </div>
  </div>
  <div class="particulars-form no-background dialog-bottom-buttons">
    <div class="particulars-row no-label">
      <div class="particulars-col"></div>
      <div class="particulars-col"></div>
      <div class="particulars-col float-right">
        <span class="control-value button float-right">
          <button mat-stroked-button color="primary" [mat-dialog-close]="null" style="margin-right: 0.5rem">
            <span>{{ 'label_cancel' | translate }}</span>
          </button>
          <button mat-flat-button color="primary" (click)="onSelect()" [disabled]="!isEntitySelected()">
            <span>{{ 'label_select' | translate }}</span>
          </button>
        </span>
      </div>
    </div>
  </div>
  <!-- END DIALOG CONTENT -->
</div>

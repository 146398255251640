import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { AccessMode } from 'src/app/configs/access-mode';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entities } from 'src/app/configs/entities';
import { EntityModalConfig } from 'src/app/configs/entity-modal-dialog-config';
import {
  CustomConfigurableModalComponent,
  CustomConfigurableModalData,
} from 'src/app/core/modal/custom-configurable-modal/custom-configurable-modal.component';
import { Entity } from 'src/app/models/entity';
import { ForeignEntity, ForeignEntityRequest } from 'src/app/models/foreign-entity';
import { GenericFormFieldItem } from 'src/app/models/forms/form-field';
import { RootStoreState } from 'src/app/root-store';
import { AuthStoreSelectors } from 'src/app/root-store/auth-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import {
  GenericEntityWizardModalDialogComponent,
  GenericEntityWizardModalDialogData,
} from '../../../generic-entity-wizard-modal-dialog/generic-entity-wizard-modal-dialog.component';
import {
  GenericFormForeignModalDialogComponent,
  GenericFormForeignModalDialogData,
} from '../../../generic-form-foreign-modal-dialog/generic-form-foreign-modal-dialog.component';

@Component({
  selector: 'app-form-field-inputfield-foreign',
  templateUrl: './form-field-inputfield-foreign.component.html',
  styleUrls: ['./form-field-inputfield-foreign.component.scss'],
})
export class FormFieldInputfieldForeignComponent extends FormatComponent implements OnInit {
  @Input() public formFieldData: GenericFormFieldItem;
  @Input() public isViewMode: boolean;
  @Input() public isDirtyEnabled: boolean;
  @Input() public originalFormFieldData: GenericFormFieldItem;
  @Input() public isSavePressed: boolean;
  @Input() public isValidationFailed: boolean;
  @Input() public entityKind: string;
  @Input() public entityId: number;
  @Input() showForeignDetailsButton: boolean;
  @Input() foreignFilterEntityKind: string;
  @Input() foreignFilterEntityId: number;
  @Output() changeValueEvEm = new EventEmitter();
  @Output() autoUpdateEvEm = new EventEmitter();
  @Output() loseFocusEvEm = new EventEmitter();

  DIRTY_CLASSNAME = 'fo-dirty-behaviour';
  foreignEntityOptions: ForeignEntity[];
  // Create
  confirmCloseDialog: MatDialogRef<any, any>;
  wizardName: string;
  tempUserInputString: string;

  constructor(private dialog: MatDialog, protected store: Store<RootStoreState.State>, protected coreDataService: CoreDataService) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)), (isTenantAdmin) => {
      this.isTenantAdmin = isTenantAdmin;
    });
    this.foreignEntityOptions =
      this.formFieldData.formfieldEntityText != null && this.formFieldData.formfieldEntityValue != null
        ? [
            {
              ...new ForeignEntity(),
              entityId: this.formFieldData.formfieldEntityValue,
              entityName: this.formFieldData.formfieldEntityText,
            },
          ]
        : [];
  }

  checkSelectedOption() {
    this.tempUserInputString = this.formFieldData.formfieldEntityText;
    if (!this.foreignEntityOptions || !this.foreignEntityOptions.find((fe) => fe.entityName === this.formFieldData.formfieldEntityText)) {
      this.formFieldData.formfieldEntityText = null;
      this.formFieldData.formfieldEntityValue = null;
      this.changeValueEvEm.emit();
    }
  }

  onForeignOptionSelectionChanged(entityName: string, dialogEntity?: ForeignEntity) {
    const entity = dialogEntity ? dialogEntity : this.foreignEntityOptions.find((fe) => fe.entityName === entityName);
    this.formFieldData.formfieldEntityText = entity ? (entity as ForeignEntity).entityName : null;
    this.formFieldData.formfieldEntityValue = entity ? (entity as ForeignEntity).entityId : null;
    this.autoUpdateEvEm.emit();
    this.changeValueEvEm.emit();
    this.loseFocusEvEm.emit();
  }

  openDialog() {
    let dialogRef: MatDialogRef<any, any>;
    if (this.formFieldData.formfieldEntityKind === Entities.ADDRESS) {
      dialogRef = this.dialog.open(CustomConfigurableModalComponent, {
        panelClass: 'custom-configurable-modal',
        width: '60vw',
        height: '80vh',
        data: {
          labelName: Entities.ADDRESS,
          basePath: ApiPath.Addresses.ADDRESSES,
          linkableFlag: true,
          enableFilterRules: true,
          baseEntityKind: this.entityKind,
          baseEntityId: this.entityId,
          gridColumnsConfig: EntityModalConfig.Addresses.COLUMNS,
          gridFiltersConfig: EntityModalConfig.Addresses.FILTERS,
        } as CustomConfigurableModalData,
      });
      dialogRef.afterClosed().subscribe((entities) => {
        if (entities && entities.length > 0) {
          this.foreignEntityOptions = [entities[0] as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, entities[0]);
        }
      });
    } else if (this.formFieldData.formfieldEntityKind === Entities.ORGANISATION) {
      dialogRef = this.dialog.open(CustomConfigurableModalComponent, {
        panelClass: 'custom-configurable-modal',
        width: '60vw',
        height: '80vh',
        data: {
          labelName: Entities.ORGANISATION,
          kind: Entities.ORGANISATION,
          linkableFlag: true,
          enableFilterRules: true,
          baseEntityKind: this.entityKind,
          baseEntityId: this.entityId,
          gridColumnsConfig: EntityModalConfig.Organisation.COLUMNS,
        } as CustomConfigurableModalData,
      });
      dialogRef.afterClosed().subscribe((entities) => {
        if (entities && entities.length > 0) {
          this.foreignEntityOptions = [entities[0] as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, entities[0]);
        }
      });
    } else if (this.formFieldData.formfieldEntityKind === Entities.LOCATION) {
      dialogRef = this.dialog.open(CustomConfigurableModalComponent, {
        panelClass: 'custom-configurable-modal',
        width: '60vw',
        height: '80vh',
        data: {
          labelName: Entities.LOCATION,
          kind: Entities.LOCATION,
          linkableFlag: true,
          enableFilterRules: true,
          baseEntityKind: this.entityKind,
          baseEntityId: this.entityId,
          gridColumnsConfig: EntityModalConfig.Location.COLUMNS,
        } as CustomConfigurableModalData,
      });
      dialogRef.afterClosed().subscribe((entities) => {
        if (entities && entities.length > 0) {
          this.foreignEntityOptions = [entities[0] as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, entities[0]);
        }
      });
    } else if (this.formFieldData.formfieldEntityKind === Entities.VENDOR) {
      dialogRef = this.dialog.open(CustomConfigurableModalComponent, {
        panelClass: 'custom-configurable-modal',
        width: '60vw',
        height: '80vh',
        data: {
          labelName: Entities.VENDOR,
          kind: Entities.VENDOR,
          linkableFlag: true,
          enableFilterRules: true,
          baseEntityKind: this.entityKind,
          baseEntityId: this.entityId,
          gridColumnsConfig: EntityModalConfig.Vendor.COLUMNS,
        } as CustomConfigurableModalData,
      });
      dialogRef.afterClosed().subscribe((entities) => {
        if (entities && entities.length > 0) {
          this.foreignEntityOptions = [entities[0] as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, entities[0]);
        }
      });
    } else if (this.formFieldData.formfieldEntityKind === Entities.SERVICE_ALIAS) {
      dialogRef = this.dialog.open(CustomConfigurableModalComponent, {
        panelClass: 'custom-configurable-modal',
        width: '60vw',
        height: '80vh',
        data: {
          labelName: Entities.SERVICE_ALIAS,
          kind: Entities.SERVICE_ALIAS,
          linkableFlag: true,
          enableFilterRules: true,
          baseEntityKind: this.entityKind,
          baseEntityId: this.entityId,
          gridColumnsConfig: EntityModalConfig.ServiceAlias.COLUMNS,
        } as CustomConfigurableModalData,
      });
      dialogRef.afterClosed().subscribe((entities) => {
        if (entities && entities.length > 0) {
          this.foreignEntityOptions = [entities[0] as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, entities[0]);
        }
      });
    } else if (this.formFieldData.formfieldEntityKind === Entities.VENDOR_ALIAS) {
      dialogRef = this.dialog.open(CustomConfigurableModalComponent, {
        panelClass: 'custom-configurable-modal',
        width: '60vw',
        height: '80vh',
        data: {
          labelName: Entities.VENDOR_ALIAS,
          kind: Entities.VENDOR_ALIAS,
          linkableFlag: true,
          enableFilterRules: true,
          baseEntityKind: this.entityKind,
          baseEntityId: this.entityId,
          gridColumnsConfig: EntityModalConfig.VendorAlias.COLUMNS,
        } as CustomConfigurableModalData,
      });
      dialogRef.afterClosed().subscribe((entities) => {
        if (entities && entities.length > 0) {
          this.foreignEntityOptions = [entities[0] as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, entities[0]);
        }
      });
    } else if (this.formFieldData.formfieldEntityKind === Entities.TYPES) {
      dialogRef = this.dialog.open(CustomConfigurableModalComponent, {
        panelClass: 'custom-configurable-modal',
        width: '60vw',
        height: '80vh',
        data: {
          labelName: this.formFieldData.formfieldTextname,
          kind: this.formFieldData.formfieldEntityKind,
          linkableFlag: true,
          filterEntityKind: this.foreignFilterEntityKind,
          filterEntityId: this.foreignFilterEntityId,
          baseEntityKind: this.entityKind,
          baseEntityId: this.entityId,
        } as CustomConfigurableModalData,
      });
      dialogRef.afterClosed().subscribe((entities) => {
        if (entities && entities.length > 0) {
          this.foreignEntityOptions = [entities[0] as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, entities[0]);
        }
      });
    } else {
      dialogRef = this.dialog.open(CustomConfigurableModalComponent, {
        panelClass: 'custom-configurable-modal',
        width: '60vw',
        height: '80vh',
        data: {
          labelName: this.formFieldData.formfieldTextname,
          kind: this.formFieldData.formfieldEntityKind,
          linkableFlag: true,
          filterEntityKind: this.foreignFilterEntityKind,
          filterEntityId: this.foreignFilterEntityId,
          baseEntityKind: this.entityKind,
          baseEntityId: this.entityId,
        } as CustomConfigurableModalData,
      });
      dialogRef.afterClosed().subscribe((entities) => {
        if (entities && entities.length > 0) {
          this.foreignEntityOptions = [entities[0] as ForeignEntity];
          this.onForeignOptionSelectionChanged(null, entities[0]);
        }
      });
    }
  }

  clearInput() {
    this.formFieldData.formfieldEntityText = null;
    this.formFieldData.formfieldEntityValue = null;
    this.changeValueEvEm.emit();
  }

  /** GENERIC FORM FIELDS API-PATH */

  loadDefaultOptions() {
    document.body.classList.add('waiting-mouse-cursor');
    const apiPath = this.isTenantAdmin
      ? `api/tenantadministration/entities/${this.formFieldData.formfieldEntityKind}?accessmode=${AccessMode.link}`
      : `api/entities/${this.formFieldData.formfieldEntityKind}?accessmode=${AccessMode.link}`;
    const request: ForeignEntityRequest = {
      ...new ForeignEntityRequest(),
      pageSize: 10,
      orderBy: 'entityName',
      filterEntityKind: this.foreignFilterEntityKind,
      filterEntityId: this.foreignFilterEntityId,
      baseEntityKind: this.entityKind,
      baseEntityId: this.entityId,
    };
    this.subscribe(this.coreDataService.getForeignEntity(apiPath, request), ({ data }) => {
      if (
        this.formFieldData.formfieldEntityText == null ||
        this.formFieldData.formfieldEntityText === '' ||
        this.formFieldData.formfieldEntityValue == null
      ) {
        this.foreignEntityOptions = data;
      } else {
        this.foreignEntityOptions = [
          {
            ...new ForeignEntity(),
            entityId: this.formFieldData.formfieldEntityValue,
            entityName: this.formFieldData.formfieldEntityText,
          },
          ...data.filter((d) => d.entityId !== this.formFieldData.formfieldEntityValue),
        ];
      }
      document.body.classList.remove('waiting-mouse-cursor');
    });
  }

  openForeignDetailsDialog() {
    this.dialog.open(GenericFormForeignModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      height: '90vh',
      panelClass: 'form-field-foreign-details-dialog',
      disableClose: true,
      data: {
        entity: {
          ...new Entity(),
          entityId: this.formFieldData.formfieldEntityValue,
          entityName: this.formFieldData.formfieldEntityText,
          entityKind: this.formFieldData.formfieldEntityKind,
          entityUpdate: this.isViewMode,
        },
      } as GenericFormForeignModalDialogData,
    });
  }

  /**  */
  createForeignEntity() {
    this.wizardName = this.formFieldData.formfieldEntityKind;
    const openedDialogs = this.dialog.openDialogs.length;
    const dialogRef = this.dialog.open(GenericEntityWizardModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-entity-wizard-modal-dialog',
      disableClose: true,

      data: {
        wizardName: this.wizardName,
        inputValue: this.tempUserInputString,
        relatedEntityKinds: [this.foreignFilterEntityKind],
        relatedEntityIds: [this.foreignFilterEntityId],
        foreignFilterEntityKind: this.foreignFilterEntityKind,
        foreignFilterEntityId: this.foreignFilterEntityId,
        isDocumentsTabEnabled: this.isDocumentEnabled,
      } as GenericEntityWizardModalDialogData,
    });
    if (openedDialogs > 0) {
      const elemRect = this.dialog.openDialogs[openedDialogs - 1]._containerInstance['_elementRef'].nativeElement.getBoundingClientRect();
      const top = elemRect.top + 10;
      const left = elemRect.left + 10;
      dialogRef.updatePosition({ top: `${top}px`, left: `${left}px` });
    }
    this.subscribe(dialogRef.afterClosed(), (entity) => {
      if (entity) {
        this.formFieldData.formfieldEntityText = entity ? (entity as ForeignEntity).entityName : null;
        this.formFieldData.formfieldEntityValue = entity ? (entity as ForeignEntity).entityId : null;
        this.autoUpdateEvEm.emit();
        this.changeValueEvEm.emit();
      }
    });
  }
}

<div id="filters-entities-grids-selection">
  <div id="filters-entities-grids">
    <ng-container *ngIf="isStaticData">
      <app-multiselection-static-options-grid
        [staticDataOptions]="staticDataOptions"
        [entityKind]="entityKind"
        [selectedDataSubj]="selectedDataSubj"
        (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
        (isLoadingEvEm)="isLoadingChanged($event)"
        (entitiesSelectedEvEm)="entitiesSelection($event)"
        (entitiesDeselectedEvEm)="entitiesDeselection($event)"
        (clearSelectionEvEm)="clearSelectionEvEm.emit()"
        [addModeSelectAllEnabled]="addModeSelectAllEnabled"
      ></app-multiselection-static-options-grid>
    </ng-container>
    <ng-container *ngIf="!isStaticData" [ngSwitch]="entityKind">
      <ng-container *ngSwitchCase="Entities.TYPES">
        <ng-container *ngIf="!isLoadingKind">
          <app-multiselection-types-grid
            [selectedDataSubj]="selectedDataSubj"
            (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
            (isLoadingEvEm)="isLoadingChanged($event)"
            (entitiesSelectedEvEm)="entitiesSelection($event)"
            (entitiesDeselectedEvEm)="entitiesDeselection($event)"
            (clearSelectionEvEm)="clearSelectionEvEm.emit()"
            (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
            [typeKindId]="typesKindId"
            [addModeSelectAllEnabled]="addModeSelectAllEnabled"
            [filterPeriod]="filterPeriod"
            [isReportFilter]="isReportFilter"
            [staticFilterKinds]="staticFilterKinds"
            [staticFilterIds]="staticFilterIds"
            [enableExtraQuerySelection]="enableExtraQuerySelection"
            [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
          ></app-multiselection-types-grid>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.PLATFORM">
        <app-multiselection-platform-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-platform-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.BILLINGACCOUNT">
        <app-multiselection-vendoraccount-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-vendoraccount-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.VENDOR">
        <app-multiselection-vendor-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-vendor-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.SERVICE">
        <app-multiselection-services-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-services-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.BILLINGITEM">
        <app-multiselection-billingitem-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-billingitem-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.FEEDITEM">
        <app-multiselection-feeditem-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-feeditem-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.LICENSE">
        <app-multiselection-license-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-license-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.INVOICE">
        <app-multiselection-invoice-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-invoice-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.USERS">
        <app-multiselection-user-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-user-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.DESK">
        <app-multiselection-desk-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-desk-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.ASSET_ALLOCATION">
        <app-multiselection-asset-allocations-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-asset-allocations-grid>
      </ng-container>

      <ng-container *ngSwitchCase="Entities.ORGANISATION">
        <app-multiselection-organisation-gridtree
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-organisation-gridtree>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.COSTCENTRE">
        <app-multiselection-costcentre-gridtree
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-costcentre-gridtree>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.LOCATION">
        <app-multiselection-location-gridtree
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-location-gridtree>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.PRODUCT">
        <app-multiselection-product-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-product-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.PRODUCT_FAMILY">
        <app-multiselection-productfamily-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-productfamily-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.USER_LICENSE">
        <app-multiselection-userlicense-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-userlicense-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.INDEX">
        <app-multiselection-indexes-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-indexes-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.TENANT">
        <app-multiselection-tenant-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-tenant-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.TENANT_LEDGER">
        <app-multiselection-tenant-ledger-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-tenant-ledger-grid>
      </ng-container>
      <ng-container *ngSwitchCase="Entities.CONTACT">
        <app-multiselection-contacts-grid
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableCreation]="true"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
        ></app-multiselection-contacts-grid>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <app-multiselection-generic-grid
          [entityKind]="entityKind"
          [apiPath]="apiPath"
          [relationEntityKind]="relationEntityKind"
          [selectedDataSubj]="selectedDataSubj"
          (isLoadingSelectionEvEm)="isLoadingSelectionChanged($event)"
          (isLoadingEvEm)="isLoadingChanged($event)"
          (entitiesSelectedEvEm)="entitiesSelection($event)"
          (entitiesDeselectedEvEm)="entitiesDeselection($event)"
          (clearSelectionEvEm)="clearSelectionEvEm.emit()"
          (extraQuerySelectionEvEm)="extraQuerySelectionEvEm.emit()"
          [addModeSelectAllEnabled]="addModeSelectAllEnabled"
          [filterPeriod]="filterPeriod"
          [isReportFilter]="isReportFilter"
          [staticFilterKinds]="staticFilterKinds"
          [staticFilterIds]="staticFilterIds"
          [notSelectableRowConditionField]="notSelectableRowConditionField"
          [notSelectableRowConditionValue]="notSelectableRowConditionValue"
          [enableExtraQuerySelection]="enableExtraQuerySelection"
          [extraQuerySelectionButtonKey]="extraQuerySelectionButtonKey"
          [filterColumn1]="filterColumn1"
          [filterColumn1Values]="filterColumn1Values"
          [enableCreation]="enableCreation"
          [createFieldTextnames]="createFieldTextnames"
          [createFieldValues]="createFieldValues"
        ></app-multiselection-generic-grid>
      </ng-container>
    </ng-container>
  </div>
</div>

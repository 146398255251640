<div class="base-form-field form-field-chips">
  <div class="label-container" *ngIf="formFieldData.formfieldLabelShow">
    <ng-container *ngIf="formFieldData.formfieldLabelDisplay">
      <span class="label" showIfTruncated matTooltip="{{ formFieldData.formfieldTextname | translate }}"
        >{{ formFieldData.formfieldTextname | translate }}
      </span>
      <ng-container *ngIf="!isViewMode">
        <span
          *ngIf="
            formFieldData.formfieldRequired === GenericFormFieldRequiredType.required && !formFieldData.isReadonlyField() && !isViewMode
          "
          [ngClass]="formFieldData.isRequiredControlValid ? 'requiredField' : 'requiredFieldInvalid'"
          >*</span
        >
        <span
          *ngIf="
            formFieldData.formfieldRequired === GenericFormFieldRequiredType.requiredSoonAsPossible &&
            !formFieldData.isReadonlyField() &&
            !isViewMode
          "
          class="requiredSoonAsPossibileField"
          >*</span
        >
      </ng-container>
      <span
        class="tooltip"
        *ngIf="formFieldData.formfieldInfo"
        matTooltip="{{ formFieldData.formfieldInfo | translate }}"
        [matTooltipClass]="'fo-mat-tooltip'"
        ><mat-icon class="entityTooltipIcon">info</mat-icon></span
      >
    </ng-container>
    <ng-container *ngIf="!formFieldData.formfieldLabelDisplay">
      <span class="label">&nbsp;</span>
    </ng-container>
    <button
      *ngIf="!isViewMode && formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.LIST"
      [disabled]="formFieldData.isReadonlyField()"
      mat-icon-button
      matSuffix
      class="chips-search-icon"
      (click)="openDialog(chipsMultiselectionDialog); $event.stopPropagation()"
    >
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <div class="value-container">
    <span class="value">
      <mat-form-field
        *ngIf="formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.CHIPS"
        appearance="outline"
        class="form-field-input reset-outline-hint chips-input"
        [ngClass]="[
          formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
          !formFieldData.isRequiredControlValid && isSavePressed ? 'invalid-field' : '',
          isViewMode ? 'view-mode' : '',
          isDirtyEnabled && isDirtyChips() ? DIRTY_CLASSNAME : ''
        ]"
        (click)="!isViewMode ? openDialog(chipsMultiselectionDialog) : null"
      >
        <mat-chip-grid #chipList [disabled]="formFieldData.isReadonlyField() || isViewMode">
          <mat-chip-row *ngFor="let item of availabledEntityRelations" [removable]="!isViewMode" (removed)="removeChip(item)">
            {{ item.entityName }}
            <mat-icon matChipRemove *ngIf="!isViewMode">cancel</mat-icon>
          </mat-chip-row>
          <input [matChipInputFor]="chipList" readonly [disabled]="true" />
        </mat-chip-grid>
        <button
          *ngIf="!isViewMode"
          [disabled]="formFieldData.isReadonlyField()"
          mat-icon-button
          matSuffix
          class="small-icon"
          (click)="openDialog(chipsMultiselectionDialog); $event.stopPropagation()"
        >
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
      <div
        *ngIf="
          formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.LIST ||
          (formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.MULTISELECTION_LIST &&
            (formFieldData.isReadonlyField() || isViewMode))
        "
        class="entity-table"
        #chipListTable
      >
        <div class="table-wrapper">
          <div class="table-container">
            <table class="table table-stripes no-header-table" mat-table [dataSource]="availabledEntityRelations">
              <ng-container matColumnDef="entity_name">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  {{ row.entityName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="menu_item">
                <th mat-header-cell *matHeaderCellDef [hidden]="!showMenuItem"></th>
                <td mat-cell class="iconaction-cell" *matCellDef="let row" [hidden]="!showMenuItem">
                  <mat-icon
                    [matMenuTriggerFor]="contractActionsMenu"
                    [matMenuTriggerData]="{ row: row }"
                    fontSet="fas"
                    fontIcon="fa-ellipsis-v"
                  ></mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="entity_description">
                <th mat-header-cell *matHeaderCellDef [hidden]="!showDescription"></th>
                <td mat-cell *matCellDef="let row" [hidden]="!showDescription">
                  {{ row.entityDescription }}
                </td>
              </ng-container>
              <ng-container matColumnDef="entity_info">
                <th mat-header-cell *matHeaderCellDef [hidden]="!showInfo"></th>
                <td mat-cell *matCellDef="let row" [hidden]="!showInfo">
                  {{ row.entityInfo }}
                </td>
              </ng-container>

              <ng-container matColumnDef="entity_remove">
                <th mat-header-cell *matHeaderCellDef class="icondelete-header"></th>
                <td mat-cell *matCellDef="let row" class="icondelete-cell">
                  <mat-icon
                    (click)="removeChip(row)"
                    *ngIf="!isViewMode && !formFieldData.isReadonlyField()"
                    style="color: red"
                    matTooltip="{{ 'label_action_remove' | translate }}"
                    [matTooltipClass]="'fo-mat-tooltip'"
                    fontSet="fas"
                    fontIcon="fa-times"
                  ></mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
            </table>
            <mat-toolbar class="table-footer-toolbar" *ngIf="availabledEntityRelations.length === 0">
              <mat-toolbar-row *ngIf="availabledEntityRelations.length === 0" class="no-data-message">
                <span>{{ 'generic_table_error_nodata' | translate }}</span>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
        </div>
      </div>
      <ng-container
        *ngIf="
          formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.MULTISELECTION_CHIPS &&
          (formFieldData.isReadonlyField() || isViewMode)
        "
      >
        <mat-form-field
          appearance="outline"
          class="form-field-input reset-outline-hint chips-input view-mode"
          [ngClass]="[
            formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
            !formFieldData.isRequiredControlValid && isSavePressed ? 'invalid-field' : '',
            isDirtyEnabled && isDirtyChips() ? DIRTY_CLASSNAME : ''
          ]"
        >
          <mat-chip-grid #chipMultiselectionList [disabled]="true">
            <mat-chip-row *ngFor="let item of availabledEntityRelations" [removable]="false">
              {{ item.entityName }}
            </mat-chip-row>
            <input [matChipInputFor]="chipMultiselectionList" readonly [disabled]="true" />
          </mat-chip-grid>
        </mat-form-field>
      </ng-container>
      <ng-container
        *ngIf="
          (formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.MULTISELECTION_CHIPS ||
            formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.MULTISELECTION_LIST) &&
          !formFieldData.isReadonlyField() &&
          !isViewMode
        "
      >
        <app-generic-multigrid-multiselection
          [isStaticData]="!isForeignEntityOption()"
          [staticDataOptions]="formFieldData.formfieldEntityOptions"
          [selectedDataSubj]="currentSelectionSubj.asObservable()"
          [entityKind]="formFieldData.formfieldEntityKindOther"
          [relationEntityKind]="formFieldData.formfieldEntityKindRelated"
          [staticFilterKinds]="['filterEntityKind', 'filterEntityId']"
          [staticFilterIds]="[[foreignFilterEntityKind], [foreignFilterEntityId]]"
          (addToSelectionEvEm)="addToSelection($event)"
          (removeFromSelectionEvEm)="removeFromSelection($event)"
          (clearSelectionEvEm)="clearSelection()"
        ></app-generic-multigrid-multiselection>
      </ng-container>
    </span>
  </div>
</div>
<!-- CHIPS - MULTISELECTIONS DIALOG -->
<ng-template id="chips-multiselection-dialog" #chipsMultiselectionDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>

  <div class="dialog-header">
    <span>{{ 'generic_chips_multiselection_dialog_title' | translate }}</span>
  </div>
  <div mat-dialog-content class="chips-multiselection-dialog-content">
    <app-generic-multigrid-multiselection
      [isStaticData]="!isForeignEntityOption()"
      [staticDataOptions]="formFieldData.formfieldEntityOptions"
      [selectedDataSubj]="currentSelectionSubj.asObservable()"
      [entityKind]="formFieldData.formfieldEntityKindOther"
      [relationEntityKind]="formFieldData.formfieldEntityKindRelated"
      [staticFilterKinds]="['filterEntityKind', 'filterEntityId']"
      [staticFilterIds]="[[foreignFilterEntityKind], [foreignFilterEntityId]]"
      (addToSelectionEvEm)="addToSelection($event)"
      (removeFromSelectionEvEm)="removeFromSelection($event)"
      (clearSelectionEvEm)="clearSelection()"
    ></app-generic-multigrid-multiselection>
    <div class="particulars-form no-background dialog-bottom-buttons">
      <div class="particulars-row no-label">
        <div class="particulars-col"></div>
        <div class="particulars-col"></div>
        <div class="particulars-col float-right">
          <span class="control-value button float-right">
            <button mat-stroked-button color="primary" (click)="closeDialog()" style="margin-right: 0.5rem">
              <span>{{ 'label_cancel' | translate }}</span>
            </button>
            <button mat-flat-button color="primary" (click)="$event.stopPropagation(); saveSelection()">
              <span>{{ 'label_ok' | translate }}</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<mat-menu #contractActionsMenu="matMenu" xPosition="before" overlapTrigger="false">
  <ng-template matMenuContent let-aliasMenuItems="row">
    <button mat-menu-item color="primary" (click)="jumpToContract(aliasMenuItems)">
      <span>{{ 'label_jump_to_contract' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>

<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span mat-dialog-title>
    {{ 'modal_dialog_please_select' | translate }}
    {{ 'topnavbar_menu_viewasuser_user_selection_dialog_title' | translate }}
  </span>
</div>
<div mat-dialog-content class="dialog-with-bottom-buttons">
  <!-- DIALOG CONTENT -->
  <div id="entity-table" class="entity-table">
    <div class="table-wrapper">
      <!-- SEARCH & PAGINATOR START-->
      <div class="table-search-paginate hidden-filters">
        <div class="table-search-paginate-left-wrapper">
          <mat-form-field appearance="outline" class="reset-outline-hint filter-field search"
            ><button *ngIf="filter" mat-icon-button matSuffix class="small-icon" (click)="clearSearchFilter(); $event.stopPropagation()">
              <mat-icon>clear</mat-icon>
            </button>
            <mat-autocomplete
              (opened)="matAutocompletPanelOpened()"
              (closed)="matAutocompletPanelClosed()"
              #autoLastStringOption="matAutocomplete"
              (optionSelected)="applyFilterTable($event.option.value)"
            >
              <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
                {{ 'label_recent_searches' | translate }}
              </mat-option>
              <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
                {{ opt }}
              </mat-option>
            </mat-autocomplete>
            <input
              #searchInputField
              #searchInputAutocompleteTrigger="matAutocompleteTrigger"
              (input)="searchInputAutocompleteTrigger.closePanel()"
              matInput
              [(ngModel)]="filter"
              [matAutocomplete]="autoLastStringOption"
              (debounceFilterInput)="applyFilterTable($event)"
              [debounceTime]="600"
              maxlength="1500"
              placeholder="{{ 'table_filter_placeholder' | translate }}"
            />
          </mat-form-field>
          <ng-container *ngIf="searchFilterrules">
            <span style="padding-right: 0.5rem"></span>
            <div class="search-filterrules">
              <mat-button-toggle-group
                class="topics"
                #kindButtonGroup="matButtonToggleGroup"
                [multiple]="true"
                [value]="[selectedFilteruleId]"
                (change)="setFilterRule($event.value)"
              >
                <mat-button-toggle [value]="1">
                  <span>{{ 'label_exact_match' | translate }}</span>
                </mat-button-toggle>
                <mat-button-toggle [value]="2">
                  <span>{{ 'label_begin_with' | translate }}</span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </ng-container>
        </div>
        <div class="table-search-paginate-right">
          <ng-container *ngIf="showFilters">
            <button mat-button color="primary" (click)="enableFilter = !enableFilter">
              {{ enableFilter ? ('label_hide_filters' | translate) : ('label_more_filters' | translate) }}
            </button>
            <button mat-button color="warn" (click)="clearFilters()" *ngIf="isFiltersSetted()">
              {{ 'label_reset_filters' | translate }}
            </button>
          </ng-container>
        </div>
      </div>
      <div class="table-filters" *ngIf="gridFiltersConfig" [ngClass]="enableFilter ? 'visible' : 'hidden'">
        <ng-container *ngFor="let filter of gridFiltersConfig">
          <generic-grid-filter-panel
            *ngIf="!filter.isDateFilter"
            [clearFilter]="clearFilterSubject.asObservable()"
            [filtersMapSubj]="filtersMapSubject.asObservable()"
            [filterName]="filter.filterParams"
            [filterApiPath]="filter.filterApiPath"
            filterTitle="{{ filter.filterTitle | translate }}"
            (filterIdsEvEm)="setFiltersIds($event, filter.filterParams)"
          ></generic-grid-filter-panel>
          <generic-grid-filter-date-panel
            *ngIf="filter.isDateFilter"
            [clearFilter]="clearFilterSubject.asObservable()"
            [filtersMapSubj]="filtersMapSubject.asObservable()"
            [filterName]="filter.filterParams"
            filterTitle="{{ filter.filterTitle | translate }}"
            (filterDatesEvEm)="setFiltersIds($event, filter.filterParams)"
          ></generic-grid-filter-date-panel>
        </ng-container>
      </div>
      <div class="table-container">
        <table
          *ngIf="isGridView"
          class="table table-stripes table-truncate-text"
          mat-table
          [dataSource]="dataSourceTable"
          matSort
          #sortTable="matSort"
          [matSortActive]="gbGetInitialOrderBy()"
          [matSortDirection]="gbGetInitialSort()"
          matSortDisableClear
          [matSortActive]="'entityName'"
          [matSortDirection]="'asc'"
        >
          <!-- radio button column -->
          <ng-container matColumnDef="radio">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="radio-button-wrapper">
              <mat-radio-button
                *ngIf="row.userEnabled"
                class="table-radio-button"
                (change)="row.userEnabled ? onEntitySelected(row) : null"
                disableRipple="true"
                [checked]="selectedEntity && selectedEntity.entityId === row.entityId"
              ></mat-radio-button>
            </td>
          </ng-container>

          <!-- Configurable columns -->
          <ng-container *ngIf="gridColumnsConfig">
            <ng-container *ngFor="let c of gridColumnsConfig">
              <ng-container matColumnDef="{{ c.entityField }}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }">
                  {{ c.columnTitleKey | translate }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let row"
                  [matTooltip]="getTooltipString(c, row)"
                  showIfTruncated
                  [ngSwitch]="c.columnType"
                  [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }"
                  [ngClass]="c.columnType === 'tooltip' ? 'tooltip-cell' : ''"
                >
                  <span *ngSwitchCase="'date'">{{ getDate(row[c.entityField]) }}</span>
                  <span *ngSwitchCase="'timestamp'">{{ getTimestamp(row[c.entityField]) }}</span>
                  <span *ngSwitchCase="'price'">{{
                    row[c.entityField] != null ? getMoney(row[c.entityField]) : row[c.columnPriceSwitchField]
                  }}</span>
                  <span *ngSwitchCase="'factor'">{{ getFactor(row[c.entityField]) }}</span>
                  <span *ngSwitchCase="'percentage'">{{ getPercentageNormalized(row[c.entityField]) }}</span>
                  <span *ngSwitchCase="'boolean'">
                    <ng-container *ngIf="row[c.entityField]">
                      <mat-icon style="height: auto; vertical-align: middle; color: green; font-size: 20px">check</mat-icon>
                    </ng-container>
                  </span>
                  <ng-container *ngSwitchCase="'tooltip'">
                    <mat-icon
                      *ngIf="!row[c.entityField]"
                      matTooltip="{{ 'user_disabled_for_login' | translate }}"
                      [matTooltipClass]="'fo-mat-tooltip'"
                      >info</mat-icon
                    >
                  </ng-container>

                  <span *ngSwitchDefault>{{ row[c.entityField] }}</span>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsTable"
            [ngClass]="[
              selectedEntity && selectedEntity.entityId === row.entityId ? 'selected-row' : '',
              row.userEnabled ? '' : 'disabled-user'
            ]"
          ></tr>
        </table>
        <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
          <mat-toolbar-row *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-toolbar-row>
          <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
            <span>{{ 'generic_table_error_nodata' | translate }}</span>
          </mat-toolbar-row>
        </mat-toolbar>
        <mat-toolbar class="table-footer-toolbar">
          <mat-toolbar-row>
            <mat-paginator
              [disabled]="isLoading"
              #paginatorTable
              [pageSizeOptions]="pageSizeList"
              [length]="pageTotalElements"
              class="reset-paginator"
            ></mat-paginator>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </div>
  </div>
  <div class="particulars-form no-background dialog-bottom-buttons">
    <div class="particulars-row no-label">
      <div class="particulars-col"></div>
      <div class="particulars-col"></div>
      <div class="particulars-col float-right">
        <span class="control-value button float-right">
          <button mat-stroked-button color="primary" [mat-dialog-close]="null" style="margin-right: 0.5rem">
            <span>{{ 'label_cancel' | translate }}</span>
          </button>
          <button mat-flat-button color="primary" (click)="onSelect()" [disabled]="!isEntitySelected()">
            <span>{{ 'label_select' | translate }}</span>
          </button>
        </span>
      </div>
    </div>
  </div>
  <!-- END DIALOG CONTENT -->
</div>
